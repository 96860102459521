import React from 'react';

const DragHandlerIcon = ({ size, color }) => (
  <svg width={size || '20'} height={size || '20'} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4581_121435)">
      <path
        d="M9.58333 8.62565C10.1126 8.62565 10.5417 8.19659 10.5417 7.66732C10.5417 7.13804 10.1126 6.70898 9.58333 6.70898C9.05406 6.70898 8.625 7.13804 8.625 7.66732C8.625 8.19659 9.05406 8.62565 9.58333 8.62565Z"
        fill={color || '#C8C8C8'}
      />
      <path
        d="M9.58333 16.2917C10.1126 16.2917 10.5417 15.8626 10.5417 15.3333C10.5417 14.8041 10.1126 14.375 9.58333 14.375C9.05406 14.375 8.625 14.8041 8.625 15.3333C8.625 15.8626 9.05406 16.2917 9.58333 16.2917Z"
        fill={color || '#C8C8C8'}
      />
      <path
        d="M13.4167 8.62565C13.9459 8.62565 14.375 8.19659 14.375 7.66732C14.375 7.13804 13.9459 6.70898 13.4167 6.70898C12.8874 6.70898 12.4583 7.13804 12.4583 7.66732C12.4583 8.19659 12.8874 8.62565 13.4167 8.62565Z"
        fill={color || '#C8C8C8'}
      />
      <path
        d="M13.4167 16.2917C13.9459 16.2917 14.375 15.8626 14.375 15.3333C14.375 14.8041 13.9459 14.375 13.4167 14.375C12.8874 14.375 12.4583 14.8041 12.4583 15.3333C12.4583 15.8626 12.8874 16.2917 13.4167 16.2917Z"
        fill={color || '#C8C8C8'}
      />
      <path
        d="M13.4167 12.4577C13.9459 12.4577 14.375 12.0286 14.375 11.4993C14.375 10.9701 13.9459 10.541 13.4167 10.541C12.8874 10.541 12.4583 10.9701 12.4583 11.4993C12.4583 12.0286 12.8874 12.4577 13.4167 12.4577Z"
        fill={color || '#C8C8C8'}
      />
      <path
        d="M9.58333 12.4577C10.1126 12.4577 10.5417 12.0286 10.5417 11.4993C10.5417 10.9701 10.1126 10.541 9.58333 10.541C9.05406 10.541 8.625 10.9701 8.625 11.4993C8.625 12.0286 9.05406 12.4577 9.58333 12.4577Z"
        fill={color || '#C8C8C8'}
      />
    </g>
    <defs>
      <clipPath id="clip0_4581_121435">
        <rect width="23" height="23" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DragHandlerIcon;
