export const fitToScreen = {
  minHeight: 'calc(100vh - 120px)',
  maxHeight: 'calc(100vh - 120px)',
  overflowX: 'hidden',
  overflowY: 'auto',
};

export const generateFitToScreenStyle = (elementHeight) => ({
  minHeight: `calc(100vh - ${elementHeight}px)`,
  maxHeight: `calc(100vh - ${elementHeight}px)`,
  overflowX: 'hidden',
  overflowY: 'auto',
});
