import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserInfo } from 'store/actions/general';

//  this is user's id that had been set in the coockies
const userId = Cookies.get('dark_number')

const useDarkMode = () => {
  const dispatch = useDispatch();

  const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem('isDarkMode') === 'true');

  const toggleDarkMode = () => {
    const newIsDarkMode = !isDarkMode;
    setIsDarkMode(newIsDarkMode);
    localStorage.setItem('isDarkMode', newIsDarkMode);
    dispatch(updateUserInfo({ userId, theme: newIsDarkMode && true }));
  };

  useEffect(() => {
    const body = document.querySelector('body');
    if (isDarkMode) {
      body.classList.add('dark');
    } else {
      body.classList.remove('dark');
    }
  }, [isDarkMode]);

  return [isDarkMode, toggleDarkMode];
};

export default useDarkMode;
