import React from 'react'

const ProfileSettingsIcon = ({ size, color }) => (
  <svg width={size || "28"} height={size || "28"} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.84191 19.5125C9.02566 19.0907 9.28903 18.7023 9.62066 18.3706C10.318 17.6733 11.2639 17.2812 12.25 17.2812H15.75C16.7362 17.2812 17.682 17.6733 18.3794 18.3706C18.711 18.7023 18.9744 19.0907 19.1582 19.5125C19.3034 19.8441 19.6902 19.9964 20.0227 19.8511C20.3543 19.7068 20.5065 19.3191 20.3613 18.9875C20.1119 18.4161 19.7567 17.8911 19.3078 17.4423C18.3645 16.499 17.0844 15.9688 15.75 15.9688C14.6125 15.9688 13.3875 15.9688 12.25 15.9688C10.9157 15.9688 9.63553 16.499 8.69228 17.4423C8.24341 17.8911 7.88816 18.4161 7.63878 18.9875C7.49353 19.3191 7.64578 19.7068 7.97741 19.8511C8.30991 19.9964 8.69666 19.8441 8.84191 19.5125Z" fill={color || "#1C1C1C"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M14 7.21875C11.2227 7.21875 8.96875 9.47275 8.96875 12.25C8.96875 15.0273 11.2227 17.2812 14 17.2812C16.7773 17.2812 19.0312 15.0273 19.0312 12.25C19.0312 9.47275 16.7773 7.21875 14 7.21875ZM14 8.53125C16.0527 8.53125 17.7188 10.1973 17.7188 12.25C17.7188 14.3027 16.0527 15.9688 14 15.9688C11.9473 15.9688 10.2812 14.3027 10.2812 12.25C10.2812 10.1973 11.9473 8.53125 14 8.53125Z" fill={color || "#1C1C1C"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M23.5331 11.5386C23.7361 12.3253 23.8438 13.1504 23.8438 14C23.8438 19.4329 19.4329 23.8438 14 23.8438C8.56712 23.8438 4.15625 19.4329 4.15625 14C4.15625 8.56712 8.56712 4.15625 14 4.15625C14.8496 4.15625 15.6747 4.26388 16.4614 4.46688C16.8122 4.557 17.1701 4.34612 17.2602 3.99525C17.3512 3.64437 17.1395 3.2865 16.7886 3.1955C15.897 2.96625 14.9625 2.84375 14 2.84375C7.84262 2.84375 2.84375 7.84262 2.84375 14C2.84375 20.1574 7.84262 25.1562 14 25.1562C20.1574 25.1562 25.1562 20.1574 25.1562 14C25.1562 13.0375 25.0337 12.103 24.8045 11.2114C24.7135 10.8605 24.3556 10.6488 24.0048 10.7398C23.6539 10.8299 23.443 11.1878 23.5331 11.5386Z" fill={color || "#1C1C1C"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.1541 8.35547V9.71084C21.1541 10.0731 21.4481 10.3671 21.8103 10.3671C22.1726 10.3671 22.4666 10.0731 22.4666 9.71084V8.35547C22.4666 7.99322 22.1726 7.69922 21.8103 7.69922C21.4481 7.69922 21.1541 7.99322 21.1541 8.35547Z" fill={color || "#1C1C1C"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.9667 6.91219L18.7933 7.58944C18.4792 7.77056 18.3715 8.17219 18.5527 8.48631C18.7338 8.79956 19.1354 8.90719 19.4495 8.72606L20.6229 8.04881C20.937 7.86769 21.0447 7.46606 20.8635 7.15194C20.6824 6.83869 20.2808 6.73106 19.9667 6.91219Z" fill={color || "#1C1C1C"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.6229 5.16255L19.4495 4.48442C19.1354 4.30329 18.7338 4.41092 18.5527 4.72505C18.3715 5.0383 18.4792 5.43992 18.7933 5.62104L19.9667 6.29917C20.2808 6.48029 20.6824 6.37267 20.8635 6.05854C21.0447 5.74529 20.937 5.34367 20.6229 5.16255Z" fill={color || "#1C1C1C"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.4666 4.85537V3.5C22.4666 3.13775 22.1726 2.84375 21.8103 2.84375C21.4481 2.84375 21.1541 3.13775 21.1541 3.5V4.85537C21.1541 5.21762 21.4481 5.51162 21.8103 5.51162C22.1726 5.51162 22.4666 5.21762 22.4666 4.85537Z" fill={color || "#1C1C1C"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M23.6539 6.29917L24.8281 5.62104C25.1414 5.43992 25.249 5.0383 25.0688 4.72505C24.8876 4.41092 24.4851 4.30329 24.1719 4.48442L22.9976 5.16255C22.6844 5.34367 22.5768 5.74529 22.7579 6.05854C22.939 6.37267 23.3406 6.48029 23.6539 6.29917Z" fill={color || "#1C1C1C"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.9976 8.04881L24.1719 8.72606C24.4851 8.90719 24.8876 8.79956 25.0688 8.48631C25.249 8.17219 25.1414 7.77056 24.8281 7.58944L23.6539 6.91219C23.3406 6.73106 22.939 6.83869 22.7579 7.15194C22.5768 7.46606 22.6844 7.86769 22.9976 8.04881Z" fill={color || "#1C1C1C"} />
    <path fillRule="evenodd" clipRule="evenodd" d="M21.8103 4.19922C20.4829 4.19922 19.4041 5.27722 19.4041 6.60547C19.4041 7.93372 20.4829 9.01172 21.8103 9.01172C23.1386 9.01172 24.2166 7.93372 24.2166 6.60547C24.2166 5.27722 23.1386 4.19922 21.8103 4.19922ZM21.8103 5.51172C22.4141 5.51172 22.9041 6.00172 22.9041 6.60547C22.9041 7.20922 22.4141 7.69922 21.8103 7.69922C21.2074 7.69922 20.7166 7.20922 20.7166 6.60547C20.7166 6.00172 21.2074 5.51172 21.8103 5.51172Z" fill={color || "#1C1C1C"} />
  </svg>
)

export default ProfileSettingsIcon