import { useSelector } from 'react-redux';
import { withConvertAmount } from 'utils/hoc/withConvertAmount';
import _ from 'lodash';

export function useConvertedUnit() {
  const { units } = useSelector((state) => state.general);

  const isInvalidValue = (value) =>
    typeof value === 'object' ||
    typeof value === 'undefined' ||
    (value === '' && typeof value === 'string') ||
    value === 'nan';

  const formatValue = (value, toFixed, forChart = false) => {
    const formattedValue = Number(value);
    return forChart ? formattedValue : Number(formattedValue.toFixed(toFixed ?? 8));
  };

  const convertToBaseUnit = (value, unitId, toFixed) => {
    if (isInvalidValue(value) || Number.isNaN(Number(value))) return 0;
    if (value === 'None') return value;

    const unitItem = _.find(units, { id: unitId });
    if (!unitItem) return formatValue(value, toFixed);

    const { is_base, reverse_formula } = unitItem;
    if (is_base) return formatValue(value, toFixed);

    if (reverse_formula) {
      const convertedValue = withConvertAmount(value, reverse_formula);
      return formatValue(convertedValue, toFixed);
    }

    console.error(`CAN'T CONVERT TO BASE UNIT`);
    return null;
  };

  const convertToCurrentUnit = (value, unitId, toFixed) => {
    if (isInvalidValue(value) || Number.isNaN(Number(value))) return 0;
    if (value === 'None') return value;

    const unitItem = _.find(units, { id: unitId });
    if (!unitItem) return formatValue(value, toFixed, toFixed === 'chart');

    const { formula, is_base } = unitItem;
    if (is_base) return formatValue(value, toFixed, toFixed === 'chart');

    if (formula) {
      const convertedValue = withConvertAmount(value, formula);
      return formatValue(convertedValue, toFixed, toFixed === 'chart');
    }

    console.error(`CAN'T CONVERT TO CURRENT UNIT`);
    return formatValue(value, toFixed);
  };

  return { convertToBaseUnit, convertToCurrentUnit };
}
