import React from 'react';

const AlarmOff = ({ color, size }) => (
  <svg width={size || '15'} height={size || '15'} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 14L14 1M5 5.5H3.5C3.23478 5.5 2.98043 5.60536 2.79289 5.79289C2.60536 5.98043 2.5 6.23478 2.5 6.5V8.5C2.5 8.76522 2.60536 9.01957 2.79289 9.20711C2.98043 9.39464 3.23478 9.5 3.5 9.5H5M5 5.5V9.5M5 5.5L8.95 2.69C9.09487 2.58977 9.26364 2.52958 9.43924 2.51553C9.61484 2.50148 9.79104 2.53407 9.95 2.61C10.1149 2.6931 10.2536 2.82024 10.3507 2.97733C10.4478 3.13443 10.4994 3.31533 10.5 3.5V4.5M5 9.5L5.29 9.71M7.17 11.06L8.91 12.31C9.05487 12.4102 9.22364 12.4704 9.39924 12.4845C9.57484 12.4985 9.75104 12.4659 9.91 12.39C10.0824 12.3126 10.2293 12.1879 10.3337 12.0303C10.4382 11.8728 10.4958 11.6889 10.5 11.5V7.5"
      stroke={color || '#1C1C1C'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AlarmOff;
