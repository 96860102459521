import * as types from '../types';

const initialState = {
  traces: [],
  installedTraces: [],
  colors: [],
  units: [],
  unitsCategories: [],
  projects: [],
  selectedProjects: {},
  user: {},
  finishProject: true,
  isAddingSensor: false,
  focusMode: true,
  generalLoading: true,
  autoDepthstatus: true,
  sensorsList: [],
  exportHistoryList: [],
  userStorage: {},
  operationalCode: [],
};

export default function general(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case types.GET_ALL_TRACES:
      return {
        ...state,
        traces: payload,
      };
    case types.IS_ADD_UNIT:
      return {
        ...state,
        isAddingSensor: payload,
      };
    case types.SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProjects: payload,
      };
    case types.SET_OVERLAY_LOADING:
      return {
        ...state,
        generalLoading: payload,
      };
    case types.GET_INSTALLED_TRACES:
      return {
        ...state,
        installedTraces: payload,
      };
    case types.GET_OPERATIONAL_CODE:
      return {
        ...state,
        operationalCode: payload,
      };
    case types.GET_PROJECTS:
      return {
        ...state,
        projects: payload,
      };
    case types.GET_USER_EXPORT_HISTORIES:
      return {
        ...state,
        exportHistoryList: payload,
      };
    case types.GET_ALL_UNITS:
      return {
        ...state,
        units: payload,
      };
    case types.GET_ALL_UNITS_CATEGORY:
      return {
        ...state,
        unitsCategories: payload,
      };
    case types.GET_ALL_COLORS:
      return {
        ...state,
        colors: payload,
      };
    case types.GET_CURRENT_USER:
      return {
        ...state,
        user: payload,
      };
    case types.SET_FINISH_PROJECT:
      return {
        ...state,
        finishProject: payload,
      };
    case types.GET_SENSORS:
      return {
        ...state,
        sensorsList: payload,
      };
    case types.GET_REDIS_AUTO_DEPTH:
      return {
        ...state,
        autoDepthstatus: payload,
      };
    case types.TOGGLE_FOCUS_MODE:
      localStorage.setItem('focusMode', !state.focusMode);
      return {
        ...state,
        focusMode: !state.focusMode,
      };
    case types.GET_USER_STORAGE:
      return {
        ...state,
        userStorage: payload,
      };
    case types.UPDATE_USER_STORAGE:
      return {
        ...state,
        userStorage: payload,
      };
    default:
      return state;
  }
}
