import React from 'react';

const Icon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2101 5.19099C17.7864 5.26637 17.2073 5.45801 16.3633 5.73935L9.87231 7.90302C9.05025 8.17704 8.45209 8.37659 8.01762 8.5461C7.56617 8.72224 7.36809 8.83882 7.28513 8.91854C6.67091 9.50878 6.67091 10.4914 7.28513 11.0817C7.36809 11.1614 7.56617 11.278 8.01762 11.4541C8.45209 11.6236 9.05024 11.8232 9.87231 12.0972C9.88537 12.1015 9.89826 12.1058 9.91098 12.1101C10.2029 12.2073 10.4045 12.2745 10.5875 12.3713C11.0313 12.6059 11.3941 12.9687 11.6287 13.4125C11.7255 13.5955 11.7927 13.7971 11.8899 14.089C11.8942 14.1018 11.8985 14.1146 11.9028 14.1277C12.1768 14.9498 12.3764 15.5479 12.5459 15.9824C12.722 16.4338 12.8386 16.6319 12.9183 16.7149C13.5086 17.3291 14.4912 17.3291 15.0815 16.7149C15.1612 16.6319 15.2778 16.4338 15.4539 15.9824C15.6234 15.5479 15.823 14.9498 16.097 14.1277L18.2607 7.63672C18.542 6.79269 18.7336 6.21361 18.809 5.78987C18.885 5.36251 18.8101 5.26542 18.7723 5.22769C18.7346 5.18996 18.6375 5.11497 18.2101 5.19099ZM18.035 4.20645C18.5448 4.11576 19.0801 4.1213 19.4794 4.52058C19.8787 4.91986 19.8842 5.45523 19.7936 5.96502C19.7038 6.46978 19.4874 7.11891 19.223 7.91206L19.2093 7.95295L17.0457 14.4439L17.0407 14.4588C16.7727 15.263 16.5653 15.8851 16.3855 16.3459C16.2113 16.7925 16.0354 17.1654 15.8025 17.4078C14.8188 18.4315 13.181 18.4315 12.1973 17.4078C11.9644 17.1654 11.7886 16.7925 11.6143 16.3459C11.4345 15.8851 11.2272 15.263 10.9591 14.4588L10.9541 14.4439C10.8391 14.0988 10.7981 13.9809 10.7448 13.88C10.604 13.6137 10.3863 13.3961 10.12 13.2553C10.0191 13.2019 9.90123 13.1609 9.55608 13.0459L9.54119 13.0409C8.737 12.7728 8.11489 12.5655 7.65414 12.3857C7.20753 12.2115 6.83459 12.0356 6.59224 11.8027C5.56854 10.819 5.56854 9.18122 6.59224 8.19749C6.83459 7.9646 7.20753 7.78875 7.65414 7.6145C8.1149 7.43472 8.73703 7.22735 9.54124 6.95928L9.55608 6.95433L16.0471 4.79067L16.0879 4.77706C16.8811 4.51264 17.5302 4.29624 18.035 4.20645Z"
      fill={color || '#1C1C1C'}
    />
  </svg>
);

export default Icon;
