import * as types from '../types';

const initialState = {
  all: [],
  tags: [],
  isMemosFiltered: false,
  filteredMemos: [],
  emojis: [],
  historicalChartValue: [],
  liveChartValue: [],
};

export default function memos(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case types.GET_ALL_MEMOS:
      return {
        ...state,
        all: payload,
      };
    case types.GET_ALL_MEMOS_TAGS:
      return {
        ...state,
        tags: payload,
      };
    case types.TOGGLE_MEMOS_FILTER:
      return {
        ...state,
        isMemosFiltered: payload,
      };
    case types.SET_FILTERED_MEMOS:
      return {
        ...state,
        filteredMemos: payload,
      };
    case types.GET_ALL_EMOJIS:
      return {
        ...state,
        emojis: payload,
      };
    case types.SET_HISTORICAL_CHART_VALUE:
      return {
        ...state,
        historicalChartValue: payload,
      };
    case types.SET_LIVE_CHART_VALUE:
      return {
        ...state,
        liveChartValue: payload,
      };
    default:
      return state;
  }
}
