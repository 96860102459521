import React from 'react';

const Icon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="white" />
    <path d="M12 10.3999L12 16.9999" stroke="#3C9AE8" strokeWidth="2" strokeLinecap="round" />
    <path d="M12 6.55005L12 6.00005" stroke="#3C9AE8" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default Icon;
