// utility.js

/**
 * Gets the appropriate company logo based on the theme and caches it in local storage.
 * @param {Object} companyLogos - Object containing company logos.
 * @param {Object} settingsData - Object containing settings data.
 * @param {Object} settings - Object containing settings.
 * @param {String} theme - Theme type ('light' or 'dark').
 * @returns {String} - The URL of the appropriate logo.
 */
function getLogo(clientLogo, settingsData, theme) {
  let logo;

  if (!theme) {
    logo = clientLogo?.company_logo || settingsData?.company_logo;
  } else {
    logo = clientLogo?.company_logo_dark || settingsData?.company_logo_dark;
  }

  return logo || JSON.parse(localStorage.getItem('company_logo'));
}

export default getLogo;
