import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Popover } from 'antd';

dayjs.extend(utc);
dayjs.extend(timezone);

const LogsSection = () => {
  const { logs } = useSelector((state) => state.drillerMenu);
  const [recentLogs, setRecentLogs] = useState([]);
  const [remainingLogs, setRemainingLogs] = useState([]);

  useEffect(() => {
    const recent = logs?.slice(0, 3);
    const remaining = logs?.slice(3);
    setRecentLogs(recent);
    setRemainingLogs(remaining);
  }, [logs]);

  const splitText = (message, color) => {
    const apiTimeString = message.split('#')[0];
    const currentYear = dayjs().year();
    const formattedTimeString = `${currentYear} ${apiTimeString}`;

    // Use dayjs directly without native Date()
    const utcDate = dayjs(`${formattedTimeString} UTC`, 'YYYY MMM DD HH:mm:ss').utc();
    const finalLocalTimeFormat = utcDate.local().format('MMM DD HH:mm:ss');

    const messageAfterHash = message.split('#').slice(1).join('#');

    return (
      <div className="flex items-center gap-x-1D">
        <span>{finalLocalTimeFormat}</span>
        <span style={{ color }} className="pl-1">
          #{messageAfterHash}
        </span>
      </div>
    );
  };

  const popoverContent = (
    <>
      {remainingLogs.length > 0 ? (
        <ul className="space-y-1 text-xs font-semibold pr-1 capitalize">
          {remainingLogs.map((data) => (
            <li key={data.id} className="space-x-1">
              {splitText(data.message, data.color)}
            </li>
          ))}
        </ul>
      ) : (
        <p className="w-full">No more logs available</p>
      )}
    </>
  );

  return (
    <Popover content={popoverContent} trigger="hover" placement="bottom">
      <div>
        {recentLogs.length <= 3 && (
          <ul className="space-y-1 text-xs font-semibold pr-1 capitalize">
            {recentLogs.map((data) => (
              <li key={data.id} className="space-x-1">
                {splitText(data.message, data.color)}
              </li>
            ))}
          </ul>
        )}
      </div>
    </Popover>
  );
};

export default LogsSection;
