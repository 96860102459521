import { httpService } from 'api/api';
import ApiErrorHandler from 'utils/ApiErrorHandler';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import * as types from '../types';

export const getAllMemos = (timeQuery) => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_ALL_MEMOS,
      payload: res.data,
    });
  };

  const error = async (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getAllMemos(respone, error, timeQuery);
};

export const getAllMemosTags = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_ALL_MEMOS_TAGS,
      payload: res.data,
    });
  };

  const error = async (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getAllMemosTags(respone, error);
};

export const setFilteredMemos = (data) => (dispatch) => {
  dispatch({
    type: types.SET_FILTERED_MEMOS,
    payload: data,
  });
};

export const toggleMemosFilter = (data) => (dispatch) => {
  dispatch({
    type: types.TOGGLE_MEMOS_FILTER,
    payload: data,
  });
};

export const getAllEmojis = () => async (dispatch) => {
  const resposne = (res) => {
    dispatch({
      type: types.GET_ALL_EMOJIS,
      payload: res.data,
    });
  };

  const error = async (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getAllEmojis(resposne, error);
};

export const setHistoricalChartValue = (data) => (dispatch) => {
  dispatch({
    type: types.SET_HISTORICAL_CHART_VALUE,
    payload: data,
  });
};

export const setLiveChartValue = (data) => (dispatch) => {
  dispatch({
    type: types.SET_LIVE_CHART_VALUE,
    payload: data,
  });
};

export const getAllMemosOnCurrentSelectedTime = () => async (dispatch) => {
  const isHistorical = window.location.pathname.includes('historical');
  const chartLiveValue = Cookies.get('chartValueLive') ? JSON.parse(Cookies.get('chartValueLive')) : undefined;
  const chartLiveHistorical = Cookies.get('chartValue') ? JSON.parse(Cookies.get('chartValue')) : undefined;

  const timeFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  const formatDate = (unixTimeStamp) => (unixTimeStamp ? dayjs.unix(unixTimeStamp).format(timeFormat) : '');

  const startTime = isHistorical ? formatDate(chartLiveHistorical?.[0]) : formatDate(chartLiveValue?.[0]);
  const endTime = isHistorical ? formatDate(chartLiveHistorical?.[1]) : undefined;

  const timeQuery = isHistorical
    ? `start=${encodeURIComponent(startTime)}&end=${encodeURIComponent(endTime)}`
    : `start=${encodeURIComponent(startTime)}`;

  const respone = (res) => {
    dispatch({
      type: types.GET_ALL_MEMOS,
      payload: res.data,
    });
  };

  const error = async (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getAllMemos(respone, error, timeQuery);
};
