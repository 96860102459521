import React from 'react';

const Icon = ({ color, bgColor }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill={bgColor || '#D9D9D9'} />
    <path
      d="M23.7337 21.6133C23.3127 20.616 22.7016 19.71 21.9346 18.9459C21.17 18.1797 20.2642 17.5687 19.2672 17.1468C19.2583 17.1424 19.2494 17.1401 19.2404 17.1357C20.6311 16.1312 21.5351 14.495 21.5351 12.6491C21.5351 9.59102 19.0574 7.11334 15.9994 7.11334C12.9413 7.11334 10.4636 9.59102 10.4636 12.6491C10.4636 14.495 11.3677 16.1312 12.7583 17.1379C12.7494 17.1424 12.7404 17.1446 12.7315 17.1491C11.7315 17.5709 10.8342 18.1758 10.0641 18.9482C9.29782 19.7128 8.68687 20.6186 8.26498 21.6156C7.85052 22.5916 7.627 23.638 7.6065 24.6982C7.60591 24.722 7.61008 24.7457 7.61879 24.7679C7.6275 24.7901 7.64056 24.8103 7.6572 24.8273C7.67384 24.8444 7.69373 24.8579 7.71569 24.8672C7.73765 24.8764 7.76124 24.8812 7.78507 24.8812H9.12436C9.22257 24.8812 9.3007 24.8031 9.30293 24.7071C9.34757 22.9839 10.0395 21.37 11.2628 20.1468C12.5284 18.8812 14.2092 18.1848 15.9994 18.1848C17.7895 18.1848 19.4703 18.8812 20.736 20.1468C21.9592 21.37 22.6511 22.9839 22.6958 24.7071C22.698 24.8053 22.7761 24.8812 22.8744 24.8812H24.2136C24.2375 24.8812 24.2611 24.8764 24.283 24.8672C24.305 24.8579 24.3249 24.8444 24.3415 24.8273C24.3582 24.8103 24.3712 24.7901 24.3799 24.7679C24.3886 24.7457 24.3928 24.722 24.3922 24.6982C24.3699 23.6312 24.1489 22.5933 23.7337 21.6133V21.6133ZM15.9994 16.4883C14.9748 16.4883 14.0105 16.0888 13.2851 15.3633C12.5596 14.6379 12.1601 13.6736 12.1601 12.6491C12.1601 11.6245 12.5596 10.6602 13.2851 9.93477C14.0105 9.20932 14.9748 8.80977 15.9994 8.80977C17.0239 8.80977 17.9882 9.20932 18.7136 9.93477C19.4391 10.6602 19.8386 11.6245 19.8386 12.6491C19.8386 13.6736 19.4391 14.6379 18.7136 15.3633C17.9882 16.0888 17.0239 16.4883 15.9994 16.4883Z"
      fill={color || 'white'}
    />
  </svg>
);

export default Icon;
