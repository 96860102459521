import React, { useEffect, useState } from 'react';
import { Form, Input, DatePicker, TimePicker, Select, Table, Modal } from 'antd';
import dayjs from 'dayjs';
import ButtonWithIcon from 'components/shared/ButtonWithIcon';
import { httpService } from 'api/api';
import ApiErrorHandler from 'utils/ApiErrorHandler';
import { EditIcon, TrashIcon } from 'components/icons';
import useDarkMode from 'hooks/useDarkMode';
import { useDispatch, useSelector } from 'react-redux';
import { getOperationalCode } from 'store/actions/general';

// Modal Form Component for Creating Operational Codes
const CreateOperationalCode = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const { name, code, description } = values;
    await onCreate({ name, code, description }); // Call the addOperatedLogs function with the new code details
    form.resetFields(); // Clear form fields after creation
  };

  return (
    <Modal
      visible={visible}
      title="Create a new Operational Code"
      footer={null} // Disable default footer
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical" name="create_operational_code">
        <Form.Item
          label="Code"
          name="code"
          type="number"
          rules={[{ required: true, message: 'Please enter a code code' }]}
        >
          <Input placeholder="Enter code code" />
        </Form.Item>
        <Form.Item
          label="description"
          name="description"
          rules={[{ required: true, message: 'Please enter a code description' }]}
        >
          <Input placeholder="Enter code description" />
        </Form.Item>
        <Form.Item
          label="name"
          name="name"
          // rules={[{ required: true, message: 'Please enter a code name' }]}
        >
          <Input placeholder="Enter code name" />
        </Form.Item>
        <Form.Item>
          <ButtonWithIcon
            title="Add"
            type="button"
            className="rounded bg-primary text-white font-semibold disabled:bg-gray-300 disabled:border-gray-300 border-primary hover:text-white hover:bg-primary/90 hover:border-primary/90 min-w-[100px] hover:scale-100"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  onFinish(values);
                })
                .catch((info) => {
                  console.log('Validation Failed:', info);
                });
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const EditLogModal = ({ visible, onCancel, log, codes, onSave, rawData = [] }) => {
  const [form] = Form.useForm();

  // Force `rawData` to be an array, if it's not already
  // const dataArray = Array.isArray(rawData) ? rawData : [];

  // Debugging: Log rawData type if it's not an array
  useEffect(() => {
    if (!Array.isArray(rawData)) {
      console.warn('Warning: `rawData` is not an array. Received:', rawData);
    }
  }, [rawData]);

  // Set form values when `log` changes
  useEffect(() => {
    if (log) {
      form.setFieldsValue({
        code: log.code,
        oc_date: log.oc_date ? dayjs(log.oc_date) : null,
        oc_time: log.oc_time ? dayjs(log.oc_time, 'HH:mm:ss') : null,
      });
    }
  }, [log, form]);

  const onFinish = (values) => {
    const updatedValues = {
      code: values?.code,
      oc_date: values?.oc_date?.format('YYYY-MM-DD'),
      oc_time: values?.oc_time?.format('HH:mm'),
    };
    onSave(updatedValues, log.id);
  };

  return (
    <Modal visible={visible} title="Edit Operational Log" footer={null} onCancel={onCancel}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        {/* Code Selection */}
        <Form.Item label="Operational Code" name="code" rules={[{ required: true, message: 'Please select a code' }]}>
          <Select placeholder="Select a code">
            {codes.map((code) => (
              <Select.Option key={code.id} value={code.code}>
                Code: {code.code} - {code.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {/* Date Picker for oc_date */}
        <Form.Item label="Date" name="oc_date" rules={[{ required: true, message: 'Please select a date' }]}>
          <DatePicker className="w-full" />
        </Form.Item>

        {/* Time Picker for oc_time */}
        <Form.Item label="Time" name="oc_time" rules={[{ required: true, message: 'Please select a time' }]}>
          <TimePicker format="HH:mm" className="w-full" />
        </Form.Item>

        {/* Save and Cancel Buttons */}
        <div className="flex items-center gap-x-2">
          <ButtonWithIcon type="submit" title="Save" className="min-w-[100px] border-primary text-primary" />
          <ButtonWithIcon title="Cancel" className="min-w-[100px]" onClick={onCancel} />
        </div>
      </Form>
    </Modal>
  );
};

// Operational Logs Component
const OperationalLogs = ({ onDelete, codes, onEdit, isDarkMode }) => {
  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <span>{record?.operation_code?.name || ''}</span>,
    },
    { title: 'Date', dataIndex: 'oc_date', key: 'oc_date' },
    { title: 'Time', dataIndex: 'oc_time', key: 'oc_time' },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <div className="flex items-center gap-x-2">
          <ButtonWithIcon
            className="border-0 p-0 m-0"
            icon={<EditIcon color={isDarkMode ? '#FFF' : '#000'} />}
            title=""
            onClick={() => onEdit(record)}
          />
          <ButtonWithIcon
            className="border-0 p-0 m-0"
            icon={<TrashIcon color="red" />}
            title=""
            onClick={() => onDelete(record)}
          />
        </div>
      ),
    },
  ];

  // const exportPDF = () => {
  //   // eslint-disable-next-line new-cap
  //   const doc = new jsPDF();
  //   doc.text('Operational Logs', 10, 10);
  //   logs.forEach((log, index) => {
  //     doc.text(`${index + 1}. ${log.name || ''} - ${getCodeName(log.code)} - ${log.datetime}`, 10, 20 + index * 10);
  //   });
  //   doc.save('operational-logs.pdf');
  // };

  return (
    <div className="p-4">
      <Table pagination={false} virtual columns={columns} dataSource={codes} rowKey="datetime" />
      {/* <Button type="primary" onClick={exportPDF} className="mt-4">
        Export to PDF
      </Button> */}
    </div>
  );
};

const { Option } = Select;

// Main Operational Codes Component
const ActionOperationalCode = ({ isAddingFromHeader, onSubmitOperationalCode, onclose }) => {
  const [form] = Form.useForm();
  const [logs, setLogs] = useState([]);
  const [editingLog, setEditingLog] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const { operationalCode } = useSelector((state) => state.general);
  const [isDarkMode] = useDarkMode();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOperationalCode());
    operatedLogs();
  }, []);

  useEffect(() => {
    setCodes(operationalCode);
  }, [operationalCode]);

  const [codes, setCodes] = useState(operationalCode);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const addOperatedLogs = (newCode) => {
    const { code, time, date } = newCode;

    const response = () => {
      // Fetch updated codes after adding
      operatedLogs();
      // setLogs((prevLogs) => [...prevLogs, { name, code, description }]);
      setIsEditModalVisible(false);
      dispatch(getOperationalCode());
      onSubmitOperationalCode();
    };

    const error = (err) => {
      ApiErrorHandler(err);
      operatedLogs();
    };

    httpService.addOperatedLogs(response, error, {
      operation_code: code,
      oc_date: dayjs(date).format('YYYY-MM-DD'),
      oc_time: dayjs(time).format('HH:mm'),
    });
  };

  // const getOperationalCode = () => {
  //   const response = (res) => {
  //     setCodes(res.data);
  //   };

  //   const error = () => {};

  //   httpService.getOperationalCode(response, error);
  // };

  const operatedLogs = () => {
    const response = (res) => {
      setLogs(res.data);
    };

    const error = (err) => {
      ApiErrorHandler(err);
    };

    httpService.operatedLogs(response, error);
  };

  const editOperatedLogs = (updatedLog, id) => {
    const response = () => {
      operatedLogs();
    };

    const error = (err) => {
      ApiErrorHandler(err);
    };

    httpService.editOperatedLogs(response, error, updatedLog, id);
  };

  const deleteOperatedLogs = (record) => {
    const response = () => {
      operatedLogs();
    };

    const error = (err) => {
      ApiErrorHandler(err);
    };

    httpService.deleteOperatedLogs(response, error, record.id);
  };

  const editLog = (log) => {
    setEditingLog(log);
    setIsEditModalVisible(true);
  };

  const handleEditSave = (updatedLog, id) => {
    editOperatedLogs(updatedLog, id);
    // setLogs((prevLogs) => prevLogs.map((log) => (log.datetime === updatedLog.datetime ? updatedLog : log)));
    setIsEditModalVisible(false);
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    addOperatedLogs(values);
  };

  return (
    <>
      <div className="p-4 flex items-center flex-row-reverse justify-end">
        <div>
          <CreateOperationalCode
            visible={isModalVisible}
            onCreate={(newCode) => addOperatedLogs(newCode)}
            onCancel={handleModalCancel}
          />
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish} className="max-w-sm flex-1">
          <Form.Item label="Operational Code" name="code" rules={[{ required: true, message: 'Please select a code' }]}>
            <Select
              placeholder="Select a code"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
            >
              {codes.map((code) => (
                <Option key={code.id} value={code.code}>
                  Code: {code.code} - {code.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className="flex items-center gap-x-4 pt-2">
            <Form.Item className="flex-1" label="Date" name="date">
              <DatePicker className="flex-1 w-full" />
            </Form.Item>
            <Form.Item className="flex-1" label="Time" name="time">
              <TimePicker className="flex-1 w-full" />
            </Form.Item>
          </div>
          <div className="flex items-center gap-x-2">
            <Form.Item>
              <ButtonWithIcon
                title="submit"
                type="submit"
                className="rounded bg-primary text-white font-semibold disabled:bg-gray-300 disabled:border-gray-300 border-primary hover:text-white hover:bg-primary/90 hover:border-primary/90 min-w-[100px] hover:scale-100"
              />
            </Form.Item>
            <Form.Item>
              <ButtonWithIcon
                title="cancel"
                type="button"
                onClick={onclose}
                className="rounded bg-error text-white font-semibold disabled:bg-gray-300 disabled:border-gray-300 border-error hover:text-white hover:bg-error/90 hover:border-primary/90 min-w-[100px] hover:scale-100"
              />
            </Form.Item>
          </div>
        </Form>
      </div>
      {!isAddingFromHeader && (
        <>
          <OperationalLogs
            logs={logs?.reverse() || []}
            codes={logs}
            onEdit={editLog}
            onDelete={deleteOperatedLogs}
            isDarkMode={isDarkMode}
          />
          {editingLog && (
            <EditLogModal
              visible={isEditModalVisible}
              onCancel={handleEditModalCancel}
              log={editingLog}
              codes={codes}
              onSave={handleEditSave}
              deleteActionCode={deleteOperatedLogs}
            />
          )}
        </>
      )}
    </>
  );
};

export default ActionOperationalCode;
