import React from 'react';

const AlarmOn = ({ color, size }) => (
  <svg width={size || '16'} height={size || '12'} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 3.98765H2C1.73478 3.98765 1.48043 4.09301 1.29289 4.28055C1.10536 4.46808 1 4.72244 1 4.98765V6.98765C1 7.25287 1.10536 7.50722 1.29289 7.69476C1.48043 7.8823 1.73478 7.98765 2 7.98765H3.5M3.5 3.98765V7.98765M3.5 3.98765L7.5 1.17765C7.64487 1.07742 7.81364 1.01723 7.98924 1.00319C8.16484 0.989137 8.34104 1.02173 8.5 1.09765C8.65559 1.18742 8.78414 1.31744 8.87212 1.47405C8.9601 1.63066 9.00428 1.80807 9 1.98765V9.98765C8.99581 10.1766 8.93816 10.3605 8.83373 10.518C8.72931 10.6755 8.58239 10.8002 8.41 10.8777C8.25104 10.9536 8.07484 10.9862 7.89924 10.9721C7.72364 10.9581 7.55487 10.8979 7.41 10.7977L3.5 7.98765"
      stroke={color || '#1C1C1C'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4994 0C13.367 0 13.2399 0.0592539 13.1462 0.164746C13.0526 0.270369 13 0.413546 13 0.562827C13 0.712108 13.0526 0.855285 13.1462 0.960909C13.7342 1.62351 14.2007 2.41016 14.5189 3.27593C14.8371 4.14171 15.0009 5.06965 15.0009 6.00678C15.0009 6.9439 14.8371 7.87185 14.5189 8.73762C14.2007 9.6034 13.7342 10.39 13.1462 11.0526C13.0579 11.1594 13.0098 11.3007 13.0121 11.4466C13.0144 11.5926 13.0669 11.7318 13.1584 11.835C13.25 11.9382 13.3736 11.9973 13.5031 11.9999C13.6326 12.0025 13.7579 11.9483 13.8526 11.8488C16.7158 8.6221 16.7158 3.39146 13.8526 0.164746C13.7589 0.0592539 13.6319 0 13.4994 0Z"
      fill={color || 'black'}
    />
    <path
      d="M11.3329 3C11.2446 3 11.1599 3.03456 11.0975 3.0961C11.0351 3.15772 11 3.24124 11 3.32832C11 3.4154 11.0351 3.49892 11.0975 3.56053C11.4895 3.94705 11.8004 4.40592 12.0126 4.91096C12.2248 5.416 12.334 5.9573 12.334 6.50395C12.334 7.05061 12.2248 7.59191 12.0126 8.09695C11.8004 8.60198 11.4895 9.06086 11.0975 9.44738C11.0386 9.50967 11.0065 9.59206 11.0081 9.67719C11.0096 9.76232 11.0446 9.84355 11.1056 9.90375C11.1667 9.96396 11.249 9.99845 11.3354 9.99995C11.4217 10.0015 11.5053 9.96985 11.5684 9.91181C13.4772 8.02956 13.4772 4.97835 11.5684 3.0961C11.5059 3.03456 11.4212 3 11.3329 3Z"
      fill={color || 'black'}
    />
  </svg>
);

export default AlarmOn;
