import * as types from '../types';

const initialState = {
  loading: false,
};

export default function reportReducer(state = initialState, action = {}) {
  const { type } = action;
  switch (type) {
    case types.TOGGLE_REPORT_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    default:
      return state;
  }
}
