import React from 'react';

const CarbonReport = ({ size, color }) => (
  <svg width={size || '25'} height={size || '25'} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5625 12.3125H11.8125V13.625H6.5625V12.3125ZM6.5625 9.03125H14.4375V10.3438H6.5625V9.03125ZM6.5625 15.5938H9.84375V16.9062H6.5625V15.5938Z"
      fill={color || '#1C1C1C'}
    />
    <path
      d="M16.4062 3.78125H14.4375V3.125C14.4375 2.7769 14.2992 2.44306 14.0531 2.19692C13.8069 1.95078 13.4731 1.8125 13.125 1.8125H7.875C7.5269 1.8125 7.19306 1.95078 6.94692 2.19692C6.70078 2.44306 6.5625 2.7769 6.5625 3.125V3.78125H4.59375C4.24565 3.78125 3.91181 3.91953 3.66567 4.16567C3.41953 4.41181 3.28125 4.74565 3.28125 5.09375V18.875C3.28125 19.2231 3.41953 19.5569 3.66567 19.8031C3.91181 20.0492 4.24565 20.1875 4.59375 20.1875H16.4062C16.7543 20.1875 17.0882 20.0492 17.3343 19.8031C17.5805 19.5569 17.7188 19.2231 17.7188 18.875V5.09375C17.7188 4.74565 17.5805 4.41181 17.3343 4.16567C17.0882 3.91953 16.7543 3.78125 16.4062 3.78125ZM7.875 3.125H13.125V5.75H7.875V3.125ZM16.4062 18.875H4.59375V5.09375H6.5625V7.0625H14.4375V5.09375H16.4062V18.875Z"
      fill={color || '#1C1C1C'}
    />
  </svg>
);

export default CarbonReport;
