import React from 'react';

const PrimeFileExport = ({ size, color }) => (
  <svg width={size || '25'} height={size || '25'} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9792 14.4368C13.6033 14.4368 13.2917 14.7485 13.2917 15.1243V16.4993C13.2917 17.1318 12.7783 17.6452 12.1458 17.6452H5.27083C4.63833 17.6452 4.125 17.1318 4.125 16.4993V5.49935C4.125 4.86685 4.63833 4.35352 5.27083 4.35352H8.25V8.70768C8.25 9.08352 8.56167 9.39518 8.9375 9.39518H13.2917V10.541C13.2917 10.9168 13.6033 11.2285 13.9792 11.2285C14.355 11.2285 14.6667 10.9168 14.6667 10.541V8.70768C14.6667 8.52435 14.5933 8.35018 14.465 8.22185L9.42333 3.18018C9.29453 3.05121 9.11978 2.97868 8.9375 2.97852H5.27083C3.8775 2.97852 2.75 4.10602 2.75 5.49935V16.4993C2.75 17.8927 3.8775 19.0202 5.27083 19.0202H12.1458C13.5392 19.0202 14.6667 17.8927 14.6667 16.4993V15.1243C14.6667 14.7485 14.355 14.4368 13.9792 14.4368ZM9.625 5.32518L12.32 8.02018H9.625V5.32518ZM19.195 13.0985C19.1583 13.181 19.1125 13.2543 19.0483 13.3185L16.2983 16.0685C16.1608 16.206 15.9867 16.2702 15.8125 16.2702C15.6383 16.2702 15.4642 16.206 15.3267 16.0685C15.1988 15.9391 15.1271 15.7646 15.1271 15.5827C15.1271 15.4008 15.1988 15.2262 15.3267 15.0968L16.9033 13.5202H8.9375C8.56167 13.5202 8.25 13.2085 8.25 12.8327C8.25 12.4568 8.56167 12.1452 8.9375 12.1452H16.9033L15.3267 10.5685C15.0608 10.3027 15.0608 9.86268 15.3267 9.59685C15.5925 9.33102 16.0325 9.33102 16.2983 9.59685L19.0483 12.3468C19.1125 12.411 19.1583 12.4843 19.195 12.5668C19.2683 12.7318 19.2683 12.9243 19.195 13.0893V13.0985Z"
      fill={color || '#1C1C1C'}
    />
  </svg>
);

export default PrimeFileExport;
