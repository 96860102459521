import React from 'react';

const DangerIcon = ({ color, size }) => (
  <svg width={size || 20} height={size || 20} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.25 7.5C12.4489 7.5 12.6397 7.57902 12.7803 7.71967C12.921 7.86032 13 8.05109 13 8.25V13.25C13 13.4489 12.921 13.6397 12.7803 13.7803C12.6397 13.921 12.4489 14 12.25 14C12.0511 14 11.8603 13.921 11.7197 13.7803C11.579 13.6397 11.5 13.4489 11.5 13.25V8.25C11.5 8.05109 11.579 7.86032 11.7197 7.71967C11.8603 7.57902 12.0511 7.5 12.25 7.5ZM12.25 17.25C12.5152 17.25 12.7696 17.1446 12.9571 16.9571C13.1446 16.7696 13.25 16.5152 13.25 16.25C13.25 15.9848 13.1446 15.7304 12.9571 15.5429C12.7696 15.3554 12.5152 15.25 12.25 15.25C11.9848 15.25 11.7304 15.3554 11.5429 15.5429C11.3554 15.7304 11.25 15.9848 11.25 16.25C11.25 16.5152 11.3554 16.7696 11.5429 16.9571C11.7304 17.1446 11.9848 17.25 12.25 17.25Z"
      fill={color || '#F94144'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.54415 4.726C9.61615 3.365 10.7522 2.5 12.2502 2.5C13.7482 2.5 14.8842 3.365 15.9562 4.726C17.0102 6.066 18.1262 8.046 19.5562 10.581L19.9922 11.353C21.1732 13.448 22.1072 15.103 22.5972 16.43C23.0972 17.788 23.2172 19.02 22.4592 20.107C21.7242 21.162 20.4972 21.593 18.9492 21.797C17.4082 22 15.3342 22 12.6752 22H11.8252C9.16515 22 7.09215 22 5.55115 21.797C4.00315 21.593 2.77615 21.162 2.04115 20.107C1.28315 19.02 1.40215 17.787 1.90315 16.43C2.39315 15.102 3.32715 13.448 4.50815 11.353L4.94415 10.581C6.37315 8.046 7.49015 6.065 8.54415 4.726ZM9.72315 5.654C8.74915 6.891 7.68715 8.77 6.21515 11.38L5.85115 12.025C4.62515 14.199 3.75415 15.749 3.31115 16.95C2.87315 18.136 2.93315 18.764 3.27115 19.25C3.63215 19.766 4.30915 20.12 5.74715 20.31C7.17915 20.498 9.15315 20.5 11.8872 20.5H12.6142C15.3472 20.5 17.3212 20.498 18.7542 20.31C20.1912 20.12 20.8682 19.766 21.2282 19.25C21.5672 18.764 21.6282 18.136 21.1902 16.95C20.7462 15.749 19.8752 14.199 18.6492 12.025L18.2852 11.38C16.8132 8.77 15.7512 6.891 14.7772 5.654C13.8122 4.43 13.0632 4 12.2502 4C11.4372 4 10.6882 4.429 9.72315 5.654Z"
      fill={color || '#F94144'}
    />
  </svg>
);

export default DangerIcon;
