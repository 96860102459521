import React from 'react';
import { Button, Popover } from 'antd';
import { Avatar, Logout, Person } from 'components/icons';
import Cookies from 'js-cookie';
import DarkModeToggle from 'components/shared/DarkModeToggle';
import useDarkMode from 'hooks/useDarkMode';
import { Link } from 'react-router-dom';
import ToggleAudibleAlarm from 'components/shared/ToggleAudibleAlarm';
import ApiToastHandle from 'containers/ApiToastHandle';

const Content = ({ isReportPage }) => {
  const [isDarkMode, toggleDarkMode] = useDarkMode();

  const funcLogout = () => {
    Cookies.remove('userToken');
    Cookies.remove('refresh');
    localStorage.removeItem('refresh');
    localStorage.removeItem('userToken');
    Cookies.remove('is_active');
    Cookies.remove('role');
    Cookies.remove('username');
    Cookies.remove('activeTab');
    Cookies.remove('currentSetup');
    Cookies.remove('dark_number');
    Cookies.remove('chartValue');
    Cookies.remove('chartValueLive');
    ApiToastHandle(200, 'logout', 'success');
    setTimeout(() => {
      window.location.replace('/login');
    }, 2000);
  };

  return (
    <div style={{ minWidth: '120px' }} className="flex flex-col p-2 space-y-3 text-xs capitalize">
      <Link to="/profile" onClick={() => Cookies.set('activeTab', 'accountSettings')}>
        <div className="flex items-end gap-x-1 font-normal h-6">
          <div>
            <Person color={isDarkMode ? '#FFFFFF' : undefined} />
          </div>
          <div className="text-sm dark:text-gray-200">{Cookies.get('username') || 'User'}</div>
        </div>
      </Link>
      {!isReportPage && (
        <div className="pl-[2px]">
          <DarkModeToggle isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
        </div>
      )}
      <ToggleAudibleAlarm />
      <div className="flex items-center space-x-2 font-normal h-6 pl-[2px]">
        <Button
          type="ghost"
          className="flex justify-start items-centertext-sm w-full p-0 gap-x-2 text-error capitalize"
          onClick={funcLogout}
          icon={<Logout color="#E84749" size={20} />}
        >
          log out
        </Button>
      </div>
    </div>
  );
};

const UserPopover = ({ isDarkMode, isReportPage }) => (
  <>
    <Popover
      style={{ width: '120px' }}
      placement="bottomLeft"
      content={<Content isReportPage={isReportPage} />}
      trigger="click"
    >
      <Button className="p-0 w-fit m-0 outline-none border-none  shadow-none">
        <Avatar bgColor={isDarkMode && '#505050'} />
      </Button>
    </Popover>
  </>
);

export default UserPopover;
