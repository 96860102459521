import React from 'react';

const Icon = ({ size }) => (
  <svg data-testid="info-instruction-icon" width={size || '16'} height={size || '16'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3169_86110)">
      <path
        d="M8.00016 11.8332C8.13277 11.8332 8.25995 11.7805 8.35372 11.6867C8.44748 11.593 8.50016 11.4658 8.50016 11.3332V7.33317C8.50016 7.20056 8.44748 7.07339 8.35372 6.97962C8.25995 6.88585 8.13277 6.83317 8.00016 6.83317C7.86755 6.83317 7.74038 6.88585 7.64661 6.97962C7.55284 7.07339 7.50016 7.20056 7.50016 7.33317V11.3332C7.50016 11.6092 7.72416 11.8332 8.00016 11.8332ZM8.00016 4.6665C8.17697 4.6665 8.34654 4.73674 8.47157 4.86177C8.59659 4.98679 8.66683 5.15636 8.66683 5.33317C8.66683 5.50998 8.59659 5.67955 8.47157 5.80457C8.34654 5.9296 8.17697 5.99984 8.00016 5.99984C7.82335 5.99984 7.65378 5.9296 7.52876 5.80457C7.40373 5.67955 7.3335 5.50998 7.3335 5.33317C7.3335 5.15636 7.40373 4.98679 7.52876 4.86177C7.65378 4.73674 7.82335 4.6665 8.00016 4.6665Z"
        fill="#3C8CA3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833496 7.99992C0.833496 4.04192 4.04216 0.833252 8.00016 0.833252C11.9582 0.833252 15.1668 4.04192 15.1668 7.99992C15.1668 11.9579 11.9582 15.1666 8.00016 15.1666C4.04216 15.1666 0.833496 11.9579 0.833496 7.99992ZM8.00016 1.83325C6.36466 1.83325 4.79615 2.48295 3.63967 3.63943C2.4832 4.7959 1.8335 6.36442 1.8335 7.99992C1.8335 9.63542 2.4832 11.2039 3.63967 12.3604C4.79615 13.5169 6.36466 14.1666 8.00016 14.1666C9.63566 14.1666 11.2042 13.5169 12.3607 12.3604C13.5171 11.2039 14.1668 9.63542 14.1668 7.99992C14.1668 6.36442 13.5171 4.7959 12.3607 3.63943C11.2042 2.48295 9.63566 1.83325 8.00016 1.83325Z"
        fill="#3C8CA3"
      />
    </g>
    <defs>
      <clipPath id="clip0_3169_86110">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
