import React from 'react';

const Icon = ({ size, color }) => (
  <svg width={size || '19'} height={size || '19'} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3188_85716)">
      <path
        d="M18.4519 0H0.548068C0.245404 0 0 0.245404 0 0.548068V1.82693C0 2.73355 0.737586 3.47117 1.64424 3.47117H6.02886V5.84617C6.02886 6.14887 6.27427 6.39424 6.57693 6.39424H7.49038V16.8077C7.49038 16.9419 7.53966 17.0715 7.62883 17.1718L9.09039 18.816C9.1944 18.9331 9.34347 19 9.50004 19C9.6566 19 9.80563 18.9331 9.90969 18.816L11.3712 17.1718C11.4604 17.0715 11.5097 16.9419 11.5097 16.8077V6.39424H12.4231C12.7258 6.39424 12.9712 6.14884 12.9712 5.84617V3.47117H17.3558C18.2625 3.47117 19.0001 2.73359 19.0001 1.82693V0.548068C19 0.245404 18.7546 0 18.4519 0ZM10.4134 16.5993L9.5 17.627L8.58655 16.5993V16.4157L10.4135 15.5022L10.4134 16.5993ZM10.4134 14.2767L8.58652 15.1901V13.858L10.4134 12.9445V14.2767ZM10.4134 11.719L8.58652 12.6324V11.3002L10.4134 10.3868V11.719ZM10.4134 9.16127L8.58652 10.0747V8.58655H10.4134V9.16127ZM10.4134 7.49038H8.58652V6.39424H10.4134V7.49038ZM11.875 5.29807H7.125V3.47114H11.875V5.29807ZM17.9039 1.82693C17.9039 2.12915 17.658 2.375 17.3558 2.375H1.64424C1.34202 2.375 1.09617 2.12915 1.09617 1.82693V1.09614H17.9039V1.82693Z"
        fill={color || '#1C1C1C'}
      />
    </g>
    <defs>
      <clipPath id="clip0_3188_85716">
        <rect width="19" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
