import React from 'react';

const OutlineMoon = ({ size, color }) => (
  <svg width={size || '18'} height={size || '18'} viewBox={`0 0 18 18`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.74097 17.917C9.27401 17.9168 8.80771 17.8817 8.34597 17.812C6.32381 17.4922 4.46754 16.5029 3.07462 15.0025C1.6817 13.5021 0.832786 11.5775 0.663912 9.53722C0.495039 7.4969 1.01598 5.45896 2.14327 3.74998C3.27055 2.04099 4.9389 0.759904 6.88097 0.112012C7.0888 0.0413593 7.31224 0.0302323 7.52606 0.0798876C7.73988 0.129543 7.93555 0.238002 8.09097 0.393012C8.25938 0.557426 8.3789 0.765308 8.43628 0.993563C8.49365 1.22182 8.48662 1.46151 8.41597 1.68601C8.14924 2.55331 8.02992 3.45922 8.06297 4.36601C8.13832 5.78826 8.57987 7.16691 9.34482 8.36829C10.1098 9.56968 11.1722 10.553 12.429 11.223C13.561 11.8652 14.8385 12.207 16.14 12.216C16.3666 12.2192 16.5881 12.2843 16.7803 12.4043C16.9726 12.5243 17.1284 12.6946 17.2308 12.8968C17.3332 13.099 17.3783 13.3254 17.3612 13.5514C17.3442 13.7774 17.2656 13.9945 17.134 14.179C16.2827 15.3398 15.1695 16.2833 13.8848 16.9328C12.6002 17.5824 11.1805 17.9196 9.74097 17.917ZM7.26097 1.05001C7.23886 1.05025 7.21693 1.05396 7.19597 1.06101C5.98027 1.46444 4.87699 2.14918 3.97589 3.0595C3.07478 3.96982 2.40131 5.08002 2.01027 6.29976C1.61924 7.5195 1.52168 8.81433 1.72553 10.0789C1.92939 11.3435 2.4289 12.542 3.18343 13.5771C3.93795 14.6122 4.92618 15.4545 6.0677 16.0355C7.20922 16.6166 8.47179 16.9199 9.75268 16.9209C11.0336 16.9218 12.2966 16.6204 13.439 16.0411C14.5814 15.4618 15.5709 14.6209 16.327 13.587C16.3507 13.5529 16.3645 13.5129 16.3668 13.4714C16.3691 13.4299 16.3598 13.3885 16.34 13.352C16.3219 13.3123 16.293 13.2784 16.2566 13.2543C16.2201 13.2303 16.1776 13.217 16.134 13.216C14.6657 13.2068 13.2241 12.8227 11.946 12.1C10.5378 11.3458 9.34835 10.2406 8.49285 8.89154C7.63735 7.5425 7.14481 5.99535 7.06297 4.40001C7.02745 3.38205 7.16265 2.36532 7.46297 1.39201C7.47826 1.3427 7.47992 1.29017 7.46778 1.24C7.45563 1.18982 7.43012 1.14387 7.39397 1.10701C7.37681 1.08902 7.35617 1.07469 7.33331 1.06489C7.31045 1.05509 7.28584 1.05003 7.26097 1.05001Z"
      fill={color || '#1C1C1C'}
    />
  </svg>
);

export default OutlineMoon;
