import React from 'react';

const AppErrorUi = () => (
  <div className="h-screen w-screen flex flex-col justify-center items-center gap-3">
    <img src="/shared/noServerResponse.jpg" alt="" />
    <h1 className="text-2xl font-bold">Unable to reach the server.</h1>
    <h2>Please check your internet connection and try again.</h2>
  </div>
);

export default AppErrorUi;
