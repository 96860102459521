import React from 'react';

const Icon = ({ color, size }) => (
  // eslint-disable-next-line react/no-unknown-property
  <svg width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="5" width="16" height="5" rx="1" stroke={color || '#1C1C1C'} strokeLinejoin="round" />
    <rect x="4" y="14" width="16" height="5" rx="1" stroke={color || '#1C1C1C'} strokeLinejoin="round" />
  </svg>
);

export default Icon;
