import * as types from '../types';

const initialState = {
  syncChartsData: [],
  logs: [],
  wobValue: '',
  fetchingWobValueStatus: '',
};

export default function drillerMenu(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_DRILLER_MENU_SYNC_CHARTS_DATA:
      return {
        ...state,
        syncChartsData: payload,
      };
    case types.GET_DRILLER_LOGS:
      return {
        ...state,
        logs: payload,
      };
    case types.SET_WOB_VALUE_STATUS:
      return {
        ...state,
        fetchingWobValueStatus: payload,
      };
    case types.GET_WOB_VALUE:
      return {
        ...state,
        wobValue: payload,
      };
    default:
      return state;
  }
}
