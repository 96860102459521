import * as types from '../types';

const initialState = {
  plcStatus: false,
  historyScalePLC: {}
};

export default function plc(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_PLC_STATUS:
      return {
        ...state,
        plcStatus: payload,
      };
    case types.READ_PLC_SCALE_HISTORY:
      return {
        ...state,
        historyScalePLC: payload,
      };
    default:
      return state;
  }
}
