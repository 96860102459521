import { Button } from 'antd';
import { OutlineMoon } from 'components/icons';
import Sun from 'components/icons/Sun';

function DarkModeToggle({ isDarkMode, toggleDarkMode }) {
  return (
    <Button
      type="ghost"
      className="flex justify-start items-center text-sm w-full p-0 gap-x-2 h-6 capitalize"
      onClick={toggleDarkMode}
      icon={
        isDarkMode ? (
          <Sun size={18} color={isDarkMode ? '#fff' : undefined} />
        ) : (
          <OutlineMoon size={16} color={isDarkMode ? '#fff' : undefined} />
        )
      }
    >
      {isDarkMode ? 'light' : 'dark'} mode
    </Button>
  );
}

export default DarkModeToggle;
