import { Spin } from 'antd';
import React from 'react';

const LazyLoadingIndicator = () => (
  <div className="flex flex-col items-center justify-center h-full">
    <Spin size="default" />
  </div>
);

export default LazyLoadingIndicator;
