import React from 'react';

const Icon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5 6C6.5 5.44772 6.94772 5 7.5 5H19.5C20.0523 5 20.5 5.44772 20.5 6V21C20.5 21.5523 20.0523 22 19.5 22H7.5C6.94772 22 6.5 21.5523 6.5 21V6Z"
      stroke={color || '#1C1C1C'}
      strokeLinejoin="round"
    />
    <path
      d="M17.5 5V3C17.5 2.44772 17.0523 2 16.5 2H4C3.86739 2 3.74021 2.05268 3.64645 2.14645C3.55268 2.24021 3.5 2.36739 3.5 2.5V18C3.5 18.5523 3.94772 19 4.5 19H6.5M10.5 11H16.5M10.5 15H16.5"
      stroke={color || '#1C1C1C'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
