import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { useConvertedUnit } from 'hooks/useConvertedUnit';
import { toggleAudibleAlarm } from 'store/actions/audibleAlarm';
import { useLocation } from 'react-router-dom';
import alarmSound from './alarm1.mp3';

export function useAudibleAlert(listOfTrace) {
  const dispatch = useDispatch();
  const location = useLocation();

  const { audible_alert } = useSelector((state) => state.general.user);
  const realTimeDataHistory = useSelector((state) => state.liveData.dataLiveAlert);
  const realTimeDataMain = useSelector((state) => state.liveData.data);
  const realTimeData = location.pathname.includes('/historical') ? realTimeDataHistory : realTimeDataMain;
  const [shouldAlert, setShouldAlert] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const { convertToCurrentUnit } = useConvertedUnit();
  const [listOfSnooz, setListOfSnooz] = useState([]);
  const [shouldAlertId, setShouldAlertId] = useState([]);

  const audioRef = useRef(null);

  useEffect(() => {
    const snoozList = listOfTrace.map((item) => {
      const low = item.low ? Number(item.low) : null;
      const high = item.high ? Number(item.high) : null;
      const value = convertToCurrentUnit(
        realTimeData?.[item.parameter],
        item.unit.id || item.unit,
        item?.unit?.toFixNumber || item?.unit_detail?.to_fixed || item?.unit_detail?.toFixNumber
      );
  
      if (
        Object.keys(realTimeData)?.length > 0 &&
        (low !== null && value < low) ||
        (high !== null && value > high && !item.snooze)
      ) {
        if (!shouldAlertId.includes(item.id)) setShouldAlertId((prev) => [...prev, item.id]);
        return item.snooze;
      }
      setShouldAlertId((currentIds) => _.filter(currentIds, (id) => id !== item.id));
      return true;
    });
    setListOfSnooz(snoozList);
  }, [listOfTrace, realTimeData]);
  
  

  // useEffect(() => {
  //   const snoozList = listOfTrace.map((item) => {
  //     if (
  //       Object.keys(realTimeData)?.length > 0 &&
  //       typeof Number(item.low) === 'number' &&
  //       typeof Number(item.high) === 'number' &&
  //       (convertToCurrentUnit(
  //         realTimeData?.[item.parameter],
  //         item.unit.id || item.unit,
  //         item?.unit?.toFixNumber || item?.unit_detail?.to_fixed || item?.unit_detail?.toFixNumber
  //       ) < Number(item.low) ||
  //         (convertToCurrentUnit(
  //           realTimeData?.[item.parameter],
  //           item.unit.id || item.unit,
  //           item?.unit?.toFixNumber || item?.unit_detail?.to_fixed || item?.unit_detail?.toFixNumber
  //         ) > Number(item.high) &&
  //           !item.snooze))
  //     ) {
  //       if (!shouldAlertId.includes(item.id)) setShouldAlertId((prev) => [...prev, item.id]);
  //       return item.snooze;
  //     }
  //     setShouldAlertId((currentIds) => _.filter(currentIds, (id) => id !== item.id));
  //     return true;
  //   });
  //   setListOfSnooz(snoozList);
  // }, [listOfTrace, realTimeData]);

  useEffect(() => {
    const handleInteraction = () => {
      setHasInteracted(true);
    };

    document.addEventListener('click', handleInteraction);
    document.addEventListener('keydown', handleInteraction);

    return () => {
      document.removeEventListener('click', handleInteraction);
      document.removeEventListener('keydown', handleInteraction);
    };
  }, []);

  useEffect(() => {
    let hasAlert = false;

    if (listOfSnooz.length > 0 && listOfSnooz.includes(false)) {
      hasAlert = true;
    }
    setShouldAlert(hasAlert);
  }, [listOfSnooz]);

  useEffect(() => {
    if (audible_alert && shouldAlert && hasInteracted) {
      if (!audioRef.current) {
        const audio = new Audio(alarmSound);
        audio.loop = true;
        audioRef.current = audio;
      }

      audioRef.current.play().catch((error) => {
        console.error('Error playing audio:', error);
      });
    } else {
      // eslint-disable-next-line no-lonely-if
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [audible_alert, shouldAlert, hasInteracted]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  const handleTurnOffAlert = () => {
    if (audioRef.current) {
      Cookies.set('alertStatus', 'off');
      dispatch(toggleAudibleAlarm(false));
      setShouldAlert(false);
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  const activateAlarm = () => {
    if (!shouldAlert) {
      // Logic to activate the alarm
      setShouldAlert(true);
    }
  };

  return { shouldAlert, handleTurnOffAlert, activateAlarm, shouldAlertId };
}
