import React from 'react';

const AnimatedAlarm = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24">
    <g fill="none" stroke="#E84749" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
      <path strokeDasharray="240" strokeDashoffset="240" d="M12 3L21 20H3L12 3Z">
        <animate attributeName="stroke-dashoffset" dur="3s" values="260;0" repeatCount="indefinite" />
      </path>
      <path strokeDasharray="24" strokeDashoffset="24" d="M12 10V14">
        <animate attributeName="stroke-dashoffset" begin="0.4s" dur="0.8s" values="10" repeatCount="indefinite" />
      </path>
    </g>
    <circle cx="12" cy="17" r="1" fill="#E84749" fillOpacity="0">
      <animate attributeName="fill-opacity" begin="0.6s" dur="1s" values="10" repeatCount="indefinite" />
    </circle>
  </svg>
);

export default AnimatedAlarm;
