import React from 'react';

const Icon = ({ color, width, height }) => (
  <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 15L15 9" stroke={color || '#1C1C1C'} />
    <path d="M15 15.0004L9 9.00037" stroke={color || '#1C1C1C'} />
  </svg>
);

export default Icon;
