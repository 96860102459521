import React from 'react';

const Scale = ({ size, color }) => (
  <svg width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 7V19M5 19H17M5 19L11 13" stroke={color || '#1C1C1C'} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6 3H4C3.44772 3 3 3.44772 3 4V6C3 6.55228 3.44772 7 4 7H6C6.55228 7 7 6.55228 7 6V4C7 3.44772 6.55228 3 6 3Z"
      stroke={color || '#1C1C1C'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 17H18C17.4477 17 17 17.4477 17 18V20C17 20.5523 17.4477 21 18 21H20C20.5523 21 21 20.5523 21 20V18C21 17.4477 20.5523 17 20 17Z"
      stroke={color || '#1C1C1C'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Scale;
