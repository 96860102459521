import React from 'react';

const Icon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1318 1.51943L1.85494 19.5039C1.47399 20.1705 1.95536 21 2.72318 21H23.2768C24.0446 21 24.526 20.1705 24.1451 19.5039L13.8682 1.51943C13.4843 0.847611 12.5157 0.847611 12.1318 1.51943Z"
      fill="white"
      stroke="white"
    />
    <path
      d="M12.1318 1.51942L1.34198 20.4015C1.1896 20.6682 1.38214 21 1.68927 21L24.3107 21C24.6179 21 24.8104 20.6682 24.658 20.4015L13.8682 1.51942C13.4843 0.84761 12.5157 0.84761 12.1318 1.51942Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 17.4925L13 17.5M13 14.4946L13 7"
      stroke="#E8B339"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
