import Cookies from 'js-cookie';
import * as types from '../types';

const alertStatus = Cookies.get('alertStatus');

const initialState = {
  alert: alertStatus === 'on',
  isAMenuOpen: false,
  autoRealod: Cookies.get('shouldReaload') || false,
  mudSampleAlert: false,
};

export default function audibleAlarm(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case types.TOGGLE_AUDIBLE_ALARM:
      return {
        ...state,
        alert: payload,
      };
    case types.SET_A_MENU_OPEN:
      return {
        ...state,
        isAMenuOpen: payload,
      };
    case types.SEND_MUD_SAMPLING:
      return {
        ...state,
        mudSampleAlert: payload,
      };
    case types.CALL_AUTO_REALOD:
      return {
        ...state,
        autoRealod: payload,
      };
    default:
      return state;
  }
}
