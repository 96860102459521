import React from 'react';

const Icon = ({ size, color }) => (
  <svg width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 21H10V23H2V21ZM17 22C16.8502 21.9999 16.7023 21.9662 16.5673 21.9013C16.4323 21.8363 16.3136 21.7419 16.22 21.625L12.52 17H2V15H13C13.1498 15.0001 13.2977 15.0338 13.4327 15.0987C13.5677 15.1637 13.6864 15.2581 13.78 15.375L16.927 19.307L22.168 11.445C22.2567 11.312 22.3761 11.2021 22.5161 11.1248C22.656 11.0474 22.8125 11.0047 22.9724 11.0003C23.1322 10.9959 23.2908 11.0299 23.4348 11.0994C23.5788 11.169 23.7041 11.272 23.8 11.4L26.5 15H30V17H26C25.8448 17 25.6916 16.9639 25.5528 16.8944C25.4139 16.825 25.2931 16.7242 25.2 16.6L23.048 13.73L17.832 21.555C17.7447 21.6859 17.6278 21.7944 17.4907 21.8715C17.3536 21.9487 17.2002 21.9923 17.043 21.999L17 22Z"
      fill={color|| "black"}
    />
    <path d="M16 9H2V11H16V9Z" fill={color|| "black"} />
  </svg>
);

export default Icon;
