import { httpService } from 'api/api';
import ApiErrorHandler from 'utils/ApiErrorHandler';
import _ from 'lodash';
import * as types from '../types';
import { getDrillerLogs } from './drillerMenu';

export const getThreads = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_THREADS,
      payload: res.data,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getAllThreads(respone, error);
};

export const getTools = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_TOOLS,
      payload: _.map(res?.data, (item, index) => ({ ...item, index: res?.data?.length - index || 0 })),
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getTools(respone, error);
};

export const getToolsInserted = () => async (dispatch) => {
  const respone = (res) => {
    // dispatch(getDrillerLogs());
    dispatch({
      type: types.GET_TOOLS_INSERTED,
      payload: _.map(res?.data, (item, index) => ({ ...item, index: res?.data?.length - index || 0 })),
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getToolsInserted(respone, error);
};

export const getToolsInsertedWithStand = (query) => async (dispatch) => {
  const respone = (res) => {
    // dispatch(getDrillerLogs());
    dispatch({
      type: types.GET_TOOLS_INSERTED_WITH_STAND,
      payload: _.map(res?.data, (item, index) => ({ ...item, index: res?.data?.length - index || 0 })),
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getToolsInserted(respone, error, query);
};

export const getToolsInsertedAll = (query, forceSetCopy) => async (dispatch, getState) => {
  const { toolsInsertedCopy } = getState().addTools;

  const respone = (res) => {
    const listOfTools = _.map(res?.data, (item, index) => ({ ...item, index: res?.data?.length - index || 0 }));
    // dispatch(getDrillerLogs());

    dispatch({
      type: types.GET_TOOLS_INSERTED_ALL,
      payload: listOfTools,
    });

    if (toolsInsertedCopy?.length === 0 || forceSetCopy) {
      dispatch({
        type: types.SET_TOOLS_INSERTED_COPY,
        payload: listOfTools,
      });
    }
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getToolsInserted(respone, error, query);
};

export const getStoredStands = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_STORED_STAND,
      payload: res.data,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getMetaKey(respone, error, 'stands_group');
};

export const storedStandsLength = (number) => async (dispatch) => {
  dispatch({
    type: types.GET_STORED_STAND_LENGTH,
    payload: number,
  });
};

export const insertedStandsLength = (number) => async (dispatch) => {
  dispatch({
    type: types.GET_INSERTED_STAND_LENGTH,
    payload: number,
  });
};

export const getAllCasingTools = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_CASING_TOOLS,
      payload: res.data,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getAllCasingTools(respone, error);
};

export const getInsertedCasingTools = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_INSERTED_CASING_TOOLS,
      payload: res.data,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getCasingToolsInserted(respone, error);
};

export const getStringTypes = () => async (dispatch) => {
  const respone = (res) => {
    if (res?.data?.value) {
      dispatch({
        type: types.GET_STRING_TYPES,
        payload: res?.data?.value,
      });
    }
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getMetaKey(respone, error, 'stringTypes');
};

export const setToolsInsertedCopy = (data) => (dispatch) => {
  dispatch({
    type: types.SET_TOOLS_INSERTED_COPY,
    payload: data,
  });
};

export const toggleLastInactiveInsertedTools = () => (dispatch) => {
  dispatch({
    type: types.TOGGLE_LAST_INACTIVE_INSERTED_TOOL,
  });
};

export const toggleFirstActiveInsertedTools = () => (dispatch) => {
  dispatch({
    type: types.TOGGLE_FIRST_ACTIVE_INSERTED_TOOL,
  });
};
