import React from 'react';

const Icon = ({ size, color }) => (
  <svg style={{margin: '0 auto'}} width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 14.5L9.5 11.5" stroke={color || '#1C1C1C'} strokeLinecap="round" />
    <path d="M14.5 14.5L14.5 11.5" stroke={color || '#1C1C1C'} strokeLinecap="round" />
    <path
      d="M3 6.5H21V6.5C19.5955 6.5 18.8933 6.5 18.3889 6.83706C18.1705 6.98298 17.983 7.17048 17.8371 7.38886C17.5 7.89331 17.5 8.59554 17.5 10V15.5C17.5 17.3856 17.5 18.3284 16.9142 18.9142C16.3284 19.5 15.3856 19.5 13.5 19.5H10.5C8.61438 19.5 7.67157 19.5 7.08579 18.9142C6.5 18.3284 6.5 17.3856 6.5 15.5V10C6.5 8.59554 6.5 7.89331 6.16294 7.38886C6.01702 7.17048 5.82952 6.98298 5.61114 6.83706C5.10669 6.5 4.40446 6.5 3 6.5V6.5Z"
      stroke={color || '#1C1C1C'}
      strokeLinecap="round"
    />
    <path
      d="M9.5 3.50024C9.5 3.50024 10 2.5 12 2.5C14 2.5 14.5 3.5 14.5 3.5"
      stroke={color || '#1C1C1C'}
      strokeLinecap="round"
    />
  </svg>
);

export default Icon;
