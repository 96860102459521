import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { httpService } from 'api/api';
import { Modal } from 'antd';
import ApiErrorHandler from 'utils/ApiErrorHandler';
import ApiToastHandle from 'containers/ApiToastHandle';
import { getGaugesDriller } from 'store/actions/liveData';
import { useConvertedUnit } from 'hooks/useConvertedUnit';
import _ from 'lodash';
import { getReferenceWOB } from 'store/actions/drillerMenu';
import LoadingCircleSpinner from 'components/shared/LoadingCircleSpinner';

const AutoSetWob = () => {
  const dispatch = useDispatch();
  const { convertToBaseUnit, convertToCurrentUnit } = useConvertedUnit();

  const realTimeData = useSelector((state) => state.liveData.data);
  const gaugeTraces = useSelector((state) => state.liveData.gaugesDriller);
  const { wobValue, fetchingWobValueStatus } = useSelector((state) => state.drillerMenu);

  const [openWobModal, setOpenWobModal] = useState(false);
  const [referenceWob, setReferenceWob] = useState(wobValue);
  const [hookLoadUnit, setHookLoadUnit] = useState({
    id: null,
    category: '',
    name: '',
    symbol: '',
    formula: '',
    reverse_formula: '',
    parent: null,
    to_fixed: null,
    is_base: true,
  });

  const confirmButtonRef = useRef(null);

  useEffect(() => {
    dispatch(getGaugesDriller());

    const handleKeyDown = (event) => {
      const { keyCode } = event;

      if (keyCode === 121) {
        event.preventDefault();
        event.stopPropagation();
        handleF10Key();
      }

      if (openWobModal && keyCode === 13) {
        event.preventDefault();
        if (confirmButtonRef.current) {
          confirmButtonRef.current.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [openWobModal]);

  useEffect(() => {
    const hookLoadGauge = _.find(gaugeTraces, { parameter: 'realHookload' });
    const unit = hookLoadGauge?.unit_detail;

    if (unit) {
      setHookLoadUnit(unit);
    }
  }, [gaugeTraces]);

  useEffect(() => {
    setReferenceWob(wobValue);
  }, [wobValue]);

  const handleF10Key = () => {
    dispatch(getReferenceWOB());
    setOpenWobModal(true);
  };

  const setWobReferenceToAuto = () => {
    const response = () => {
      ApiToastHandle(200, 'Action was successful.', 'success');
      setOpenWobModal(false);
      dispatch(getReferenceWOB());
    };

    const error = (err) => {
      ApiErrorHandler(err);
    };

    httpService.setWobreference(response, error, { wob: realTimeData.realHookload });
  };

  const handleCancel = () => {
    setOpenWobModal(false);
  };

  return (
    <Modal
      open={openWobModal}
      title="Auto Reference WOB"
      centered
      maskClosable={false}
      onOk={setWobReferenceToAuto}
      onCancel={handleCancel}
      afterOpenChange={(open) => {
        if (open && confirmButtonRef.current) {
          confirmButtonRef.current.focus();
        }
      }}
      okButtonProps={{
        style: { backgroundColor: !fetchingWobValueStatus ? '#3C8CA3' : '#999999' },
        ref: confirmButtonRef,
        disabled: fetchingWobValueStatus,
      }}
      okText="Confirm"
    >
      {fetchingWobValueStatus === 'loading' && (
        <div className="text-md flex justify-start items-center gap-1">
          <LoadingCircleSpinner />
          <span className="text-primary">Fetching wob value...</span>
        </div>
      )}

      {fetchingWobValueStatus === 'error' && <p className="text-md text-error">Failed to fetch wob value.</p>}

      {!fetchingWobValueStatus && (
        <p className="text-md">
          Please confirm the new reference hookload for WOB from{' '}
          <span className="text-lg font-bold">
            {convertToCurrentUnit(referenceWob, hookLoadUnit.id, hookLoadUnit.to_fixed)}
            <span className="text-sm pl-1">({hookLoadUnit.symbol})</span>
          </span>{' '}
          to{' '}
          <span className="text-lg font-bold text-primary">
            {convertToCurrentUnit(realTimeData.realHookload, hookLoadUnit.id, hookLoadUnit.to_fixed)}
            <span className="text-sm pl-1">({hookLoadUnit.symbol})</span>
          </span>
        </p>
      )}
    </Modal>
  );
};

export default AutoSetWob;
