import { Suspense } from 'react';
import { Link, Outlet } from 'react-router-dom';
import LazyLoadingIndicator from 'components/shared/LazyLoadingIndicator';

export default function AuthLayout() {
  return (
    <div className="bg-gray-100 text-primaryDark font-normal w-screen h-screen max-h-screen overflow-hidden dark:bg-dark">
      <div
        className="w-full bg-white py-3 px-4 dark:bg-dark dark:border-b-[1px] dark:border-lighterDark/20 dark:shadow-none"
        style={{ boxShadow: '0px 4px 16px rgba(153, 153, 153, 0.2)' }}
      >
        <div className="w-fit max-w-28 min-w-12">
          <img src="/icons/png/mainlogo.png" className="h-[23px] md:h-[33px] lg:h-[43px] w-full" alt="mainlogo saxon" />
        </div>
      </div>
      <Suspense fallback={<LazyLoadingIndicator />}>
        <Outlet />
      </Suspense>
    </div>
  );
}
