import React from 'react';

const Icon = ({ size, color }) => (
  <svg width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="9" stroke={color || '#1C1C1C'} />
    <path d="M12 15L12 9" stroke={color || '#1C1C1C'} strokeLinecap="square" />
    <path d="M15 12L9 12" stroke={color || '#1C1C1C'} strokeLinecap="square" />
  </svg>
);

export default Icon;
