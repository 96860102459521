import React from 'react';

const Diagram = ({ size, color }) => (
  <svg width="275" height="155" viewBox="0 0 275 155" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4.5" y="1.2998" width="269" height="149" stroke="#D9D9D9" strokeDasharray="1 1" />
    <path d="M4 75.7998H274" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M4 90.6024H274" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M4 105.405H274" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M4 121.195H274" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M4 136.984H274" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M4 13.6288H274" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M4 30.405H274" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M4 46.1946H274" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M4 60.0104H274" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M26 0.799805L26 150.8" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M49 0.799805L49 150.8" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M71 0.799805L71 150.8" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M93 0.799805L93 150.8" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M116 0.799805L116 150.8" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M138 0.799805L138 150.8" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M161 0.799805L161 150.8" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M183 0.799805L183 150.8" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M206 0.799805L206 150.8" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M228 0.799805L228 150.8" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M251 0.799805L251 150.8" stroke="#D9D9D9" strokeDasharray="2 2" />
    <path d="M4 150.8L273 1.79992" stroke="#9DC5D0" strokeLinecap="round" />
    <path
      d="M4.35355 0.446251C4.15828 0.250989 3.8417 0.250989 3.64644 0.446251L0.46446 3.62823C0.269197 3.82349 0.269197 4.14008 0.46446 4.33534C0.659722 4.5306 0.976304 4.5306 1.17157 4.33534L3.99999 1.50691L6.82842 4.33534C7.02368 4.5306 7.34027 4.5306 7.53553 4.33534C7.73079 4.14008 7.73079 3.82349 7.53553 3.62823L4.35355 0.446251ZM4.5 150.8L4.49999 0.799805L3.49999 0.799805L3.5 150.8L4.5 150.8Z"
      fill="#D9D9D9"
    />
    <path
      d="M4 150.3C3.72386 150.3 3.5 150.524 3.5 150.8C3.5 151.076 3.72386 151.3 4 151.3V150.3ZM274.425 151.153C274.62 150.958 274.62 150.642 274.425 150.446L271.243 147.264C271.048 147.069 270.731 147.069 270.536 147.264C270.341 147.46 270.341 147.776 270.536 147.971L273.364 150.8L270.536 153.628C270.341 153.823 270.341 154.14 270.536 154.335C270.731 154.531 271.048 154.531 271.243 154.335L274.425 151.153ZM4 151.3L274.071 151.3V150.3L4 150.3V151.3Z"
      fill="#D9D9D9"
    />
  </svg>
);

export default Diagram;
