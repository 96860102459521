import React from 'react';

const LableInputs = ({ text, noP, className }) => (
  <div
    data-testid="test-lable"
    className={`dark:text-white ${
      noP ? '' : 'pb-[6px]'
    } w-fit min-w-fit text-sm text-primaryDark dark:text-lighterDark ${className}`}
  >
    {text}
  </div>
);

export default LableInputs;
