export const darkToken = {
  colorPrimary: '#FFFFFF',
  colorError: '#E84749',
  colorInfo: '#3C9AE',
  colorSuccess: '#6ABE39',
  colorWarning: '#E8D639',
  colorBgContainer: '#323030',
  colorBorderSecondary: '#464545',
  colorBgElevated: '#323030',
  controlItemBgActive: '#24789D',
  colorTextDisabled: "",
  colorBorder: "#b3b3b3"
};
