/* eslint-disable arrow-body-style */
import React from 'react';
import './loading.css';

const Loading = ({style}) => {
  return (
    <div style={style} className="h-full w-full flex flex-col justify-center items-center">
      <h2 className='font-bold text-lg'>loading</h2>
      <div className="boxes">
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
};

export default Loading;
