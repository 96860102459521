import { Select } from 'antd';
import React from 'react';

const SelectOption = ({ options, className, name, size, value, handleChange, disabled, allowSearch }) => {
  const { Option } = Select;
  return (
    <Select
      showSearch
      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      disabled={disabled}
      style={{ width: '100%', minWidth: '120px', padding: 0, margin: 0, fontSize: 10 }}
      dropdownStyle={{ fontSize: '10px' }}
      onChange={(e) => handleChange(e, name)}
      value={value}
      size={size || 'small'}
      className={`
      font-ubuntu
      focus:border-primary
      hover:border-primary
      hover:text-primary
      active::text-primary
      active:border-primary
      hover:opacity-100
      outline-none
      text-[12px]
      font-normal
      dark:bg-dark
      dark:text-white
      ${className}
    `}
    >
      {options &&
        options?.map((item) => {
          if (item?.is_hide || item?.is_disabled) return '';
          if (name === 'id' || name === 'dtime' || name === 'Depth' || name === 'datatime' || name === 'none')
            return '';
          return (
            <Option
              key={item?.id}
              value={item?.name || item?.id}
              label={item?.standard_name || item?.input || item?.name || item?.unit}
            >
              {item?.standard_name || item?.input || item?.name || item?.unit}
            </Option>
          );
        })}
    </Select>
  );
};

export default SelectOption;
