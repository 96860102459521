import * as types from '../types';

export const toggleAudibleAlarm = (data) => (dispatch) => {
  dispatch({
    type: types.TOGGLE_AUDIBLE_ALARM,
    payload: data,
  });
};

export const setAMenuOpen = (data) => (dispatch) => {
  dispatch({
    type: types.SET_A_MENU_OPEN,
    payload: data,
  });
};

export const callAutoReaload = (data) => (dispatch) => {
  dispatch({
    type: types.CALL_AUTO_REALOD,
    payload: data,
  });
};

export const sendAlertMudSampling = (data) => (dispatch) => {
  dispatch({
    type: types.SEND_MUD_SAMPLING,
    payload: data,
  });
};
