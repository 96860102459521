import React from 'react';

const Icon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="white" />
    <path d="M6.85693 6.85718L17.1426 17.1429" stroke="#E84749" strokeWidth="2" strokeLinecap="round" />
    <path d="M17.1431 6.85718L6.85735 17.1429" stroke="#E84749" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default Icon;
