import * as types from '../types';

const initialState = {
  chart_1: {
    min: 0,
    max: 0,
    minAndMaxList: [],
    tracesNames: [],
  },
  chart_2: {
    min: 0,
    max: 0,
    minAndMaxList: [],
    tracesNames: [],
  },
  chart_3: {
    min: 0,
    max: 0,
    minAndMaxList: [],
    tracesNames: [],
  },
  chart_4: {
    min: 0,
    max: 0,
    minAndMaxList: [],
    tracesNames: [],
  },
  chart_1_scaled: {
    min: 0,
    max: 0,
    minAndMaxList: [],
    tracesNames: [],
  },
  chart_2_scaled: {
    min: 0,
    max: 0,
    minAndMaxList: [],
    tracesNames: [],
  },
  chart_3_scaled: {
    min: 0,
    max: 0,
    minAndMaxList: [],
    tracesNames: [],
  },
  chart_4_scaled: {
    min: 0,
    max: 0,
    minAndMaxList: [],
    tracesNames: [],
  },
  scaledLiveDAta: [],
  isHistorical: false,
  chartLimit: null,
  selectedLine: {
    chart_1: { traceId: null },
    chart_2: { traceId: null },
    chart_3: { traceId: null },
    chart_4: { traceId: null },
  },
};

export default function syncCharts(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case types.SET_MIN_AND_MAX:
      // eslint-disable-next-line no-case-declarations
      const { chart, min, max, minAndMaxList, tracesNames } = payload;
      return {
        ...state,
        [chart]: {
          min,
          max,
          minAndMaxList,
          tracesNames,
        },
      };
    case types.SET_MIN_AND_MAX_SCALED:
      return {
        ...state,
        [`${payload.chart}_scaled`]: {
          min: payload.min,
          max: payload.max,
          minAndMaxList: payload.minAndMaxList,
          tracesNames: payload.tracesNames,
        },
      };
    case types.SET_SCALED_LIVE_DATA:
      return {
        ...state,
        scaledLiveDAta: payload,
      };
    case types.HISTORICAL:
      return {
        ...state,
        isHistorical: payload,
      };
    case types.SET_SELECTED_LINE:
      // eslint-disable-next-line no-case-declarations
      const { chartId, traceId } = payload; // assume that the payload contains the chart ID and trace ID
      return {
        ...state,
        selectedLine: {
          ...state.selectedLine,
          [chartId]: {
            traceId: traceId === state.selectedLine[chartId].traceId ? null : traceId,
          },
        },
      };
    case types.GET_CHART_LIMIT_NUMBER:
      return {
        ...state,
        chartLimit: payload,
      };
    default:
      return state;
  }
}
