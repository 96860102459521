import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { httpService } from 'api/api';
import ApiErrorHandler from 'utils/ApiErrorHandler';

const InitialProjectGuard = (props) => {
  const { children } = props;
  const location = useLocation();

  const [requestedLocation, setRequestedLocation] = useState();
  const [initialized, setInitialized] = useState(true);

  useEffect(() => {
    const getSettings = () => {
      const response = (res) => {
        if (res.data.initialized) {
          setInitialized(res.data.initialized);
        }
      };

      const error = (err) => {
        if (window.location.pathname.includes('login')) return;
        ApiErrorHandler(err);
      };
      
      httpService.settings(response, error);
    };

    getSettings();
  }, []);

  if (!initialized) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Navigate to="/setup" />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

InitialProjectGuard.propTypes = {
  children: PropTypes.node,
};

export default InitialProjectGuard;
