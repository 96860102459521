// Import your libraries
import axios from 'axios';
import Cookies from 'js-cookie';
import UAParser from 'ua-parser-js';

// Check if user is logged in
const userToken = Cookies.get('userToken');

// Function to get device information
const getDeviceInfo = () => {
  const parser = new UAParser();
  const result = parser.getResult();
  return {
    os: result.os.name || 'Unknown OS',
    osVersion: result.os.version || 'Unknown OS Version',
    browser: result.browser.name || 'Unknown Browser',
    browserVersion: result.browser.version || 'Unknown Browser Version',
    device: result.device.type || 'Unknown Device',
    deviceModel: result.device.model || 'Unknown Device Model',
    deviceVendor: result.device.vendor || 'Unknown Vendor',
    cpu: result.cpu.architecture || 'Unknown CPU Architecture',
    engineName: result.engine.name || 'Unknown Engine',
    engineVersion: result.engine.version || 'Unknown Engine Version',
    userAgent: result.ua || 'Unknown User Agent',
  };
};

// Async function to gather device info and IP
const getAllDeviceInfo = async () => {
  const deviceInfo = getDeviceInfo();
  // const ipAddress = await getIPAddress();  // Uncomment if IP is required
  return {
    ...deviceInfo,
    // ipAddress,
  };
};

// Basic Axios instance setup
const createAxiosInstance = (baseURL, contentType = 'application/json') => axios.create({
    baseURL,
    timeout: 360000,
    headers: {
      'content-type': contentType,
      Authorization: userToken ? `Bearer ${userToken}` : '',
    },
  });

// Main Axios instances
const baseURL = process.env.REACT_APP_API_BASE_PRODUCTION;
export const instance = createAxiosInstance(baseURL);
export const instanceDownload = createAxiosInstance(baseURL, 'blob');
export const instanceSoftware = createAxiosInstance(baseURL);
export const instanceSoftwareUpload = createAxiosInstance(baseURL, 'multipart/form-data');

// Remote instance with specific headers
export const remoteSoftware = axios.create({
  baseURL,
  timeout: 360000,
  headers: {
    'content-type': 'application/json',
    Authorization: userToken ? `Bearer ${userToken}` : '',
    'REMOTE-X-TOKEN': '055ce16a62acd80fc1a793e554445e2d',
    'X-INTERNAL': 1,
  },
});

// Function to create Axios instance with device info in headers
const createAxiosInstanceWithInterceptors = async (instance) => {
  instance.interceptors.request.use(
    async (config) => {
      if (process.env.NODE_ENV === 'production') {  // Only apply in production
        const deviceInfo = await getAllDeviceInfo();
        config.headers['X-Device-OS'] = deviceInfo.os;
        config.headers['X-Device-OS-Version'] = deviceInfo.osVersion;
        config.headers['X-Device-Browser'] = deviceInfo.browser;
        config.headers['X-Device-Browser-Version'] = deviceInfo.browserVersion;
        config.headers['X-Device-Type'] = deviceInfo.device;
        config.headers['X-Device-Model'] = deviceInfo.deviceModel;
        config.headers['X-Device-Vendor'] = deviceInfo.deviceVendor;
        config.headers['X-Device-CPU'] = deviceInfo.cpu;
        config.headers['X-Browser-Engine-Name'] = deviceInfo.engineName;
        config.headers['X-Browser-Engine-Version'] = deviceInfo.engineVersion;
        config.headers['X-User-Agent'] = deviceInfo.userAgent;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};

// Apply interceptors conditionally
if (process.env.NODE_ENV === 'production') {
  createAxiosInstanceWithInterceptors(instance);
  createAxiosInstanceWithInterceptors(instanceDownload);
  createAxiosInstanceWithInterceptors(instanceSoftware);
  createAxiosInstanceWithInterceptors(instanceSoftwareUpload);
}

// Intercept the response and handle 401 error
instanceSoftware.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    originalRequest._retry = true;
    if (error?.response?.data?.status_code === 401 && error?.response?.data?.error === 'Token is invalid or expired') {
      Cookies.remove('userToken');
      localStorage.removeItem('userToken');
      Cookies.remove('refresh');
      localStorage.removeItem('refresh');
    }
    // Check if it's a 401 error & not previously retried
    if (error?.response?.status === 401 && !originalRequest._retry) {
      const refreshToken = Cookies.get('refresh') || localStorage.getItem('refresh');
      if (!refreshToken || refreshToken === 'undefined') return Promise.reject(error);
      // Try to get a new token
      if (error?.response?.data?.detail === 'Token is invalid or expired') {
        Cookies.remove('userToken');
        Cookies.remove('refresh');
        localStorage.removeItem('refresh');
        localStorage.removeItem('userToken');
        Cookies.remove('is_active');
        Cookies.remove('username');
      }
      return instanceSoftware.post('/refresh', { refresh: refreshToken }).then((res) => {
        const { access } = res.data;
        Cookies.set('userToken', access);
        localStorage.setItem('userToken', access);
        // Modify the original request and retry
        // originalRequest.headers.Authorization = `Bearer ${access}`;
        return instanceSoftware(originalRequest);
        // return Promise.reject(error);
      });
    }
    return Promise.reject(error);
  }
);

export const httpService = {
  // auth
  login: (callback, catchError, data) => instanceSoftware.post('/login', data).then(callback).catch(catchError),
  initial: (callback, catchError) => instanceSoftware.get('/contexts/initial').then(callback).catch(catchError),
  settings: (callback, catchError) => instanceSoftware.get('contexts/settings').then(callback).catch(catchError),
  settingsLogo: (callback, catchError) =>
    instanceSoftware.get('contexts/settings?req=logo').then(callback).catch(catchError),
  currentUser: (callback, catchError) => instanceSoftware.get('current_user').then(callback).catch(catchError),
  updateUserInfo: (callback, catchError, id, data) =>
    instanceSoftware.patch(`edit/${id}/user`, data).then(callback).catch(catchError),
  toggleAudibleAlarmUser: (callback, catchError) =>
    instanceSoftware.patch(`user/audio/alarms`).then(callback).catch(catchError),
  resetPassword: (callback, catchError, data) =>
    instanceSoftware.put(`reset/password`, data).then(callback).catch(catchError),
  getRoles: (callback, catchError) => instanceSoftware.get(`user/roles`).then(callback).catch(catchError),
  addRole: (callback, catchError, data) => instanceSoftware.post(`user/roles`, data).then(callback).catch(catchError),
  editRole: (callback, catchError) => instanceSoftware.patch(`user/roles`).then(callback).catch(catchError),
  deleteRole: (callback, catchError, id) =>
    instanceSoftware.delete(`user/roles/${id}`).then(callback).catch(catchError),
  getGroups: (callback, catchError) => instanceSoftware.get(`user/groups`).then(callback).catch(catchError),
  addGroup: (callback, catchError, data) => instanceSoftware.post(`user/groups`, data).then(callback).catch(catchError),
  editGroup: (callback, catchError, id, data) =>
    instanceSoftware.patch(`user/groups/${id}`, data).then(callback).catch(catchError),
  deleteGroup: (callback, catchError, id) =>
    instanceSoftware.delete(`user/groups/${id}`).then(callback).catch(catchError),
  getEvents: (callback, catchError) => instanceSoftware.get(`user/events`).then(callback).catch(catchError),
  addEvents: (callback, catchError, data) =>
    instanceSoftware.post(`user/events`, data).then(callback).catch(catchError),
  editEvents: (callback, catchError, id, groups) =>
    instanceSoftware.patch(`user/events/${id}`, groups).then(callback).catch(catchError),
  deleteEvents: (callback, catchError, id) =>
    instanceSoftware.delete(`user/events/${id}`).then(callback).catch(catchError),
  // ACCESS LEVES -------------------------
  getUser: (callback, catchError) => instanceSoftware.get('list_users').then(callback).catch(catchError),
  addUser: (callback, catchError, data) => instanceSoftware.post('add/user', data).then(callback).catch(catchError),
  editUser: (callback, catchError, id, data) =>
    instanceSoftware.patch(`edit/${id}/user`, data).then(callback).catch(catchError),
  deleteUser: (callback, catchError, id) =>
    instanceSoftware.delete(`delete/${id}/user`).then(callback).catch(catchError),
  generatePassword: (callback, catchError) =>
    instanceSoftware.post(`generate/password`).then(callback).catch(catchError),
  // LOGS
  getLogs: (callback, catchError, page, qeuryFilter) =>
    instanceSoftware.get(`/log_entry/?page=${page}&page_size=100&${qeuryFilter}`).then(callback).catch(catchError),
  getLogsUserAgent: (callback, catchError) =>
    instanceSoftware.get(`/log_entry/application/logs`).then(callback).catch(catchError),
  getLogsUserAgentWithCredential: (callback, catchError, credential) =>
    instanceSoftware.get(`/log_entry/application/logs?app=${credential}`).then(callback).catch(catchError),
  getAppsLogs: (callback, catchError) =>
    instanceSoftware.get(`/log_entry/application/logs/apps`).then(callback).catch(catchError),
  // data base DB --------------------------------
  getAllHistory: (callback, catchError, db) =>
    instanceSoftware.get(`history/database/${db}`).then(callback).catch(catchError),
  getFilteredHistory: (callback, catchError, db, range) =>
    instanceSoftware.get(`history/${db}/${range}/firstAndLast`).then(callback).catch(catchError),
  getFilteredHistoryNew: (callback, catchError, db, range) =>
    instanceSoftware
      .get(`/history/${db}/based/datetime/firstAndLast?interval=${range}`)
      .then(callback)
      .catch(catchError),
  getFilteredDateHistory: (callback, catchError, db, data) =>
    instanceSoftware
      .get(`history/${db}/dates/firstAndLast?start=${data[0]}&end=${data[1]}`)
      .then(callback)
      .catch(catchError),
  getHistory: (callback, catchError, history_id, db) =>
    instanceSoftware.get(`history/${history_id}/database/${db}`).then(callback).catch(catchError),
  // getHistoryId: (callback, catchError, db, history_id) =>
  //   instanceSoftware.post(`history/${db}/list_of_ids`, history_id).then(callback).catch(catchError),
  getHistoryId: (callback, catchError, db, ids) =>
    instanceSoftware.post(`history/${db}/base/dates`, ids).then(callback).catch(catchError),
  getReportHistoryId: (callback, catchError, db, ids) =>
    instanceSoftware.post(`history/${db}/report/two/minutes`, ids).then(callback).catch(catchError),
  getHistorFirstLastID: (callback, catchError, db) =>
    instanceSoftware.get(`history/${db}/id_range`).then(callback).catch(catchError),

  // ---------------------------------------
  updateSettings: (callback, catchError, data) =>
    instanceSoftware.patch('contexts/settings/update', data).then(callback).catch(catchError),
  onSafetyAutoTally: (callback, catchError) =>
    instanceSoftware.post('drilling/auto/tally/safety').then(callback).catch(catchError),
  getSafetyAutoTally: (callback, catchError) =>
    instanceSoftware.get('drilling/auto/tally/safety').then(callback).catch(catchError),
  onSetTallyLength: (callback, catchError) =>
    instanceSoftware.post('drilling/auto/tally/safety').then(callback).catch(catchError),
  getSetTallyLength: (callback, catchError) =>
    instanceSoftware.post('drilling/auto/tally/safety').then(callback).catch(catchError),
  // data
  getWellProfileData: (callback, catchError, data) => instance.post('/', data).then(callback).catch(catchError),
  // project
  updateProjectInfo: (callback, catchError, id, data) =>
    instanceSoftware.patch(`/project/update/${id}/info`, data).then(callback).catch(catchError),
  createProject: (callback, catchError, data) =>
    instanceSoftware.post('/project/create', data).then(callback).catch(catchError),
  deleteProject: (callback, catchError, projectId) =>
    instanceSoftware.delete(`/project/${projectId}/destroy`).then(callback).catch(catchError),
  updateProjectFlag: (callback, catchError, projectId, data) =>
    instanceSoftware.patch(`/project/update/${projectId}/flags`, data).then(callback).catch(catchError),
  addSensorToProject: (callback, catchError, data) =>
    instanceSoftware.post('/project/attach/sensors', data).then(callback).catch(catchError),
  removeSensorToProject: (callback, catchError, data) =>
    instanceSoftware.post('/project/dettach/sensors', data).then(callback).catch(catchError),
  projectAddSensor: (callback, catchError, data) =>
    instanceSoftware.post('/project/add/sensor', data).then(callback).catch(catchError),

  // SETUP
  addSensorForsetup: (callback, catchError, data) =>
    instanceSoftware.post('/setup/add/sensor', data).then(callback).catch(catchError),
  uploadCsv: (callback, catchError, data) =>
    instanceSoftwareUpload.post('/setup/load/csv', data).then(callback).catch(catchError),
  downloadCsv: (callback, catchError) =>
    instanceSoftwareUpload.get('/setup/download/csv/sensors').then(callback).catch(catchError),
  checkCsv: (callback, catchError) => instanceSoftware.get('setup/check/csv/exist').then(callback).catch(catchError),
  exampleCsv: (callback, catchError) =>
    instanceSoftware.get('/setup/download/csv/example').then(callback).catch(catchError),
  updateSensor: (callback, catchError, id, data) =>
    instanceSoftware.patch(`setup/update/${id}/sensor`, data).then(callback).catch(catchError),
  updateUnit: (callback, catchError, id, data) =>
    instanceSoftware.patch(`constants/unit/${id}/update`, data).then(callback).catch(catchError),
  deleteUnit: (callback, catchError, id) =>
    instanceSoftware.delete(`constants/unit/${id}/delete`).then(callback).catch(catchError),
  toggleSnoozeTrackTrace: (callback, catchError, id, data) =>
    instanceSoftware.patch(`contexts/toggle/snooze/${id}/track`, data).then(callback).catch(catchError),
  toggleSnoozeTrace: (callback, catchError, id, data) =>
    instanceSoftware.patch(`contexts/toggle/snooze/${id}/trace`, data).then(callback).catch(catchError),
  toggleSnoozeDrillerTrace: (callback, catchError, id, data) =>
    instanceSoftware.patch(`driller/toggle/snooze/${id}/trace`, data).then(callback).catch(catchError),
  toggleTallyStringSystem: (callback, catchError, data) =>
    instanceSoftware.post(`services/tally/mode`, data).then(callback).catch(catchError),
  addUnit: (callback, catchError, data) =>
    instanceSoftware.post(`setup/add/unit`, data).then(callback).catch(catchError),
  setupInsertData: (callback, catchError) =>
    instanceSoftware.post(`setup/insert/data`).then(callback).catch(catchError),
  finishSetup: (callback, catchError) => instanceSoftware.post(`setup/finish`).then(callback).catch(catchError),
  deletSensorSetup: (callback, catchError, id) =>
    instanceSoftware.delete(`setup/delete/${id}/sensor`).then(callback).catch(catchError),
  // view
  createView: (callback, catchError, data) =>
    instanceSoftware.post('contexts/add/view', data).then(callback).catch(catchError),
  createViewDriller: (callback, catchError, data) =>
    instanceSoftware.post('drilling/views', data).then(callback).catch(catchError),
  getViewDriller: (callback, catchError, id) =>
    instanceSoftware.get(`drilling/views/${id}`).then(callback).catch(catchError),
  updateView: (callback, catchError, viewId, data) =>
    instanceSoftware.patch(`contexts/view/${viewId}/edit`, data).then(callback).catch(catchError),
  updateViewDriller: (callback, catchError, viewId, data) =>
    instanceSoftware.patch(`drilling/views/${viewId}`, data).then(callback).catch(catchError),
  removeView: (callback, catchError, viewId) =>
    instanceSoftware.delete(`contexts/remove/${viewId}/view`).then(callback).catch(catchError),
  removeDrillerView: (callback, catchError, viewId) =>
    instanceSoftware.delete(`drilling/views/${viewId}`).then(callback).catch(catchError),
  getViews: (callback, catchError) => instanceSoftware.get('contexts/views').then(callback).catch(catchError),
  getView: (callback, catchError, id) => instanceSoftware.get(`contexts/view/${id}`).then(callback).catch(catchError),
  getDrillerView: (callback, catchError) =>
    instanceSoftware.get(`drilling/views/user`).then(callback).catch(catchError),
  addTrace: (callback, catchError, data) =>
    instanceSoftware.post('contexts/add/trace', data).then(callback).catch(catchError),
  addTraceDriller: (callback, catchError, data) =>
    instanceSoftware.post('drilling/view/traces', data).then(callback).catch(catchError),
  Trace: (callback, catchError, data) =>
    instanceSoftware.post('contexts/add/trace', data).then(callback).catch(catchError),
  updateTrace: (callback, catchError, id, data) =>
    instanceSoftware.patch(`contexts/edit/${id}/trace`, data).then(callback).catch(catchError),
  updateDrillerViewTrace: (callback, catchError, id, data) =>
    instanceSoftware.patch(`drilling/view/traces/${id}`, data).then(callback).catch(catchError),
  updateDrillerGauges: (callback, catchError, id, data) =>
    instanceSoftware.patch(`drilling/gauges/${id}`, data).then(callback).catch(catchError),
  addTrackTrace: (callback, catchError, data) =>
    instanceSoftware.post('contexts/add/track', data).then(callback).catch(catchError),
  updateTrackTrace: (callback, catchError, trackId, data) =>
    instanceSoftware.patch(`contexts/update/${trackId}/track`, data).then(callback).catch(catchError),
  removeTrackTrace: (callback, catchError, id) =>
    instanceSoftware.delete(`contexts/tracks/${id}/delete`).then(callback).catch(catchError),
  removeTrace: (callback, catchError, traceId) =>
    instanceSoftware.delete(`contexts/remove/${traceId}/trace`).then(callback).catch(catchError),
  removeDrillerViewTrace: (callback, catchError, traceId) =>
    instanceSoftware.delete(`drilling/view/traces/${traceId}`).then(callback).catch(catchError),
  getChartData: (callback, catchError, chartId) =>
    instanceSoftware.get(`contexts/chart/${chartId}`).then(callback).catch(catchError),
  getViewData: (callback, catchError, viewId) =>
    instanceSoftware.get(`contexts/view/${viewId}`).then(callback).catch(catchError),
  getAllTraces: (callback, catchError) => instanceSoftware.get(`constants/sensors`).then(callback).catch(catchError),
  getInstalledTraces: (callback, catchError, id) =>
    instanceSoftware.get(`project/installed/${id}/sensors`).then(callback).catch(catchError),
  getProjects: (callback, catchError) => instanceSoftware.get(`project`).then(callback).catch(catchError),
  // constants
  getSensors: (callback, catchError) => instanceSoftware.get('constants/sensors').then(callback).catch(catchError),
  getTrackTraces: (callback, catchError) => instanceSoftware.get(`contexts/tracks`).then(callback).catch(catchError),
  addTrackDriller: (callback, catchError, data) =>
    instanceSoftware.post(`drilling/trackes`, data).then(callback).catch(catchError),
  getTrackDriller: (callback, catchError) => instanceSoftware.get(`drilling/trackes`).then(callback).catch(catchError),
  updateTrackDriller: (callback, catchError, id, data) =>
    instanceSoftware.patch(`drilling/trackes/${id}`, data).then(callback).catch(catchError),
  removeTrackDriller: (callback, catchError, id) =>
    instanceSoftware.delete(`drilling/trackes/${id}`).then(callback).catch(catchError),
  // * Tanks
  addTrackTanks: (callback, catchError, data) =>
    instanceSoftware.post(`drilling/tanks/trackes`, data).then(callback).catch(catchError),
  getTrackTanks: (callback, catchError) =>
    instanceSoftware.get(`drilling/tanks/trackes`).then(callback).catch(catchError),
  updateTrackTanks: (callback, catchError, id, data) =>
    instanceSoftware.patch(`drilling/tanks/trackes/${id}`, data).then(callback).catch(catchError),
  removeTrackTanks: (callback, catchError, id) =>
    instanceSoftware.delete(`drilling/tanks/trackes/${id}`).then(callback).catch(catchError),
  getAllUnits: (callback, catchError) => instanceSoftware.get(`constants/units`).then(callback).catch(catchError),
  getGaugesDriller: (callback, catchError) => instanceSoftware.get(`/drilling/gauges`).then(callback).catch(catchError),
  updateGaugesDriller: (callback, catchError, id, data) =>
    instanceSoftware.patch(`/drilling/gauges/${id}`, data).then(callback).catch(catchError),
  deleteGauge: (callback, catchError, id) =>
    instanceSoftware.delete(`/drilling/gauges/${id}`).then(callback).catch(catchError),
  addGaugesDriller: (callback, catchError, data) =>
    instanceSoftware.post(`/drilling/gauges`, data).then(callback).catch(catchError),
  getDrillerLogs: (callback, catchError) => instanceSoftware.get(`drilling/tools/log`).then(callback).catch(catchError),
  getAllColors: (callback, catchError) => instanceSoftware.get(`constants/colors`).then(callback).catch(catchError),
  // Memos
  getAllMemos: (callback, catchError, data) =>
    instanceSoftware
      .get(`memos/${data ? `?${data}` : ''}`)
      .then(callback)
      .catch(catchError),
  getAllMemosTags: (callback, catchError) => instanceSoftware.get(`memos/tags`).then(callback).catch(catchError),
  getFilteredMemos: (callback, catchError, data) =>
    instanceSoftware.get(`memos/tags/memos?tags=${data}`).then(callback).catch(catchError),
  updateMemo: (callback, catchError, id, data) =>
    instanceSoftware.patch(`memos/${id}/update`, data).then(callback).catch(catchError),
  createMemo: (callback, catchError, data) =>
    instanceSoftware.post(`memos/create`, data).then(callback).catch(catchError),
  getAllEmojis: (callback, catchError) => instanceSoftware.get('memos/emojis').then(callback).catch(catchError),
  addNewRock: (callback, catchError, data) =>
    instanceSoftware.post(`memos/emoji/create`, data).then(callback).catch(catchError),
  updateRockEmoji: (callback, catchError, id, data) =>
    instanceSoftware.patch(`memos/emoji/${id}/update`, data).then(callback).catch(catchError),
  deleteRockEmoji: (callback, catchError, id) =>
    instanceSoftware.delete(`memos/emoji/${id}/delete`).then(callback).catch(catchError),
  // Company
  updateCompany: (callback, catchError, data) =>
    instanceSoftwareUpload.patch(`setup/company/info`, data).then(callback).catch(catchError),
  // PLC
  readPlcParameter: (callback, catchError, sensor) =>
    instanceSoftware.post(`plc/readScale`, sensor).then(callback).catch(catchError),
  readPlcEncoder: (callback, catchError, sensor) =>
    instanceSoftware.post(`plc/read/encoder`, sensor).then(callback).catch(catchError),
  readSingleParameter: (callback, catchError, sensor) =>
    instanceSoftware.get(`plc/direct/${sensor}`).then(callback).catch(catchError),
  onFactorMotor: (callback, catchError, data) =>
    instanceSoftware.post(`plc/calc/factor/motor`, data).then(callback).catch(catchError),
  getFactorMotor: (callback, catchError, data) =>
    instanceSoftware.get(`plc/calc/factor/motor/get`, data).then(callback).catch(catchError),
  encoderPlcReset: (callback, catchError, data) =>
    instanceSoftware.post(`plc/reset/encoder`, data).then(callback).catch(catchError),
  readPlcDigital: (callback, catchError, sensor) =>
    instanceSoftware.post(`plc/read/digital`, sensor).then(callback).catch(catchError),
  writePlcEncoder: (callback, catchError, sensor) =>
    instanceSoftware.post(`plc/write/encoder`, sensor).then(callback).catch(catchError),
  writePlcDigital: (callback, catchError, sensor) =>
    instanceSoftware.post(`plc/write/digital`, sensor).then(callback).catch(catchError),
  getScaled: (callback, catchError, sensor) =>
    instanceSoftware.post(`plc/scale/parameter`, sensor).then(callback).catch(catchError),
  writePlcScale: (callback, catchError, sensor) =>
    instanceSoftware.post(`plc/writeScale`, sensor).then(callback).catch(catchError),
  writeHistoryPlcScale: (callback, catchError, sensor) =>
    instanceSoftware.post(`plc/save/sensor/calibration`, sensor).then(callback).catch(catchError),
  readHistoryPlcScale: (callback, catchError, sensor) =>
    instanceSoftware.get(`/constants/meta/${sensor}`).then(callback).catch(catchError),
  writePlcPaddle: (callback, catchError, sensor) =>
    instanceSoftware.post(`plc/writepaddle`, sensor).then(callback).catch(catchError),
  readPlcPaddle: (callback, catchError, num) =>
    instanceSoftware.get(`plc/paddle/${num}`).then(callback).catch(catchError),
  readPlcPaddlesAll: (callback, catchError) => instanceSoftware.get(`plc/paddles`).then(callback).catch(catchError),
  testPlcConnection: (callback, catchError, data) =>
    instanceSoftware.post(`setup/plc/connection/test`, data).then(callback).catch(catchError),
  mudPumpUpdate: (callback, catchError, data) =>
    instanceSoftware.patch(`plc/mudpump/save`, data).then(callback).catch(catchError),
  resetMudPump: (callback, catchError, sensor) =>
    instanceSoftware.post(`plc/reset/mp`, sensor).then(callback).catch(catchError),
  mudPumpRead: (callback, catchError, id) => instanceSoftware.get(`plc/mudpump/${id}`).then(callback).catch(catchError),
  getMudPumps: (callback, catchError) => instanceSoftware.get(`plc/mudpump`).then(callback).catch(catchError),
  setWobreference: (callback, catchError, data) =>
    instanceSoftware.post(`plc/reset/reference`, data).then(callback).catch(catchError),
  getWobReference: (callback, catchError) =>
    instanceSoftware.get(`plc/read/wob/reference`).then(callback).catch(catchError),
  setManualWobRef: (callback, catchError, data) =>
    instanceSoftware.post(`plc/set/wob/reference`, data).then(callback).catch(catchError),
  getMotorStatus: (callback, catchError) =>
    instanceSoftware.get(`constants/get/DHM/active`).then(callback).catch(catchError),
  updateMotorStatus: (callback, catchError, data) =>
    instanceSoftware.post(`constants/set/DHM/active`, data).then(callback).catch(catchError),
  // Export
  exportData: (callback, catchError, projectId, data) =>
    instanceSoftware.post(`export/${projectId}/filter`, data).then(callback).catch(catchError),
  downloadExportData: (callback, catchError, data) =>
    instanceSoftware.post(`export/download`, data).then(callback).catch(catchError),
  downloadExportLasData: (callback, catchError, data) =>
    instanceSoftware.post(`export/las/download`, data).then(callback).catch(catchError),
  getUserSavedExportTraces: (callback, catchError) =>
    instanceSoftware.get(`export/user/data`).then(callback).catch(catchError),
  getUserExportHistories: (callback, catchError) =>
    instanceSoftware.get(`export/user/configs`).then(callback).catch(catchError),
  createNewHistoryConfig: (callback, catchError, data) =>
    instanceSoftware.post(`export/user/configs`, data).then(callback).catch(catchError),
  updateHistoryConfig: (callback, catchError, id, data) =>
    instanceSoftware.patch(`export/user/configs/${id}`, data).then(callback).catch(catchError),
  deleteHistoryConfig: (callback, catchError, id) =>
    instanceSoftware.delete(`export/user/configs/${id}`).then(callback).catch(catchError),

  // Drilling
  getAllBits: (callback, catchError) => instanceSoftware.get('drilling/bits').then(callback).catch(catchError),
  bitsStatus: (callback, catchError) => instanceSoftware.get('drilling/status').then(callback).catch(catchError),
  bitsStatusUpdate: (callback, catchError, data) =>
    instanceSoftware.patch('drilling/update/status', data).then(callback).catch(catchError),
  bitsHeightUpdate: (callback, catchError, id, data) =>
    instanceSoftware.patch(`/drilling/bit/${id}/inserted`, data).then(callback).catch(catchError),
  addBit: (callback, catchError, data) => instanceSoftware.post('drilling/bits', data).then(callback).catch(catchError),
  editBit: (callback, catchError, id, data) =>
    instanceSoftware.patch(`drilling/bits/${id}`, data).then(callback).catch(catchError),
  deleteBit: (callback, catchError, id) =>
    instanceSoftware.delete(`drilling/bits/${id}`).then(callback).catch(catchError),
  uploadToolsFile: (callback, catchError, data) =>
    instanceSoftware.post(`drilling/tools/upload`, data).then(callback).catch(catchError),
  downloadToolsFile: (callback, catchError) =>
    instanceDownload.post(`drilling/tools/download`).then(callback).catch(catchError),
  updateTankPitTable: (callback, catchError, data) =>
    instanceSoftware.post(`drilling/set/tanks`, data).then(callback).catch(catchError),
  getTankPitTable: (callback, catchError) =>
    instanceSoftware.get(`drilling/get/tanks`).then(callback).catch(catchError),
  getDrillingTanks: (callback, catchError) => instanceSoftware.get(`drilling/tank`).then(callback).catch(catchError),
  addDrillingTank: (callback, catchError, data) =>
    instanceSoftware.post(`drilling/tank`, data).then(callback).catch(catchError),
  deleteDrillingTank: (callback, catchError, key) =>
    instanceSoftware.delete(`drilling/tank/${key}`).then(callback).catch(catchError),
  updateDrillingTanks: (callback, catchError, id, data) =>
    instanceSoftware.patch(`drilling/tank/${id}`, data).then(callback).catch(catchError),
  updateDrillingTanksUnitBulk: (callback, catchError, data) =>
    instanceSoftware.patch(`drilling/tank/bulk/update`, data).then(callback).catch(catchError),
  updateTankToTalAvtive: (callback, catchError, data) =>
    instanceSoftware.post(`drilling/set/volume`, data).then(callback).catch(catchError),
  resetDepth: (callback, catchError) =>
    instanceSoftware.post(`drilling/tools/reset/depth`).then(callback).catch(catchError),
  setForceCalculationsWOB: (callback, catchError, flag) =>
    instanceSoftware.post(`drilling/set/wob`, flag).then(callback).catch(catchError),
  toggleAutoTally: (callback, catchError, flag) =>
    instanceSoftware.post(`plc/drilling/active/bha`, flag).then(callback).catch(catchError),
  getAutoDepth: (callback, catchError, key) =>
    instanceSoftware.get(`/plc/redis/${key}`).then(callback).catch(catchError),
  updateRedisKey: (callback, catchError, key, data) =>
    instanceSoftware.post(`/plc/redis/${key}`, data).then(callback).catch(catchError),
  toggleCement: (callback, catchError, flag) =>
    instanceSoftware.post(`drilling/cement/enabled`, flag).then(callback).catch(catchError),
  getCementStatus: (callback, catchError) =>
    instanceSoftware.get(`drilling/cement/enabled`).then(callback).catch(catchError),
  getCementDepth: (callback, catchError) =>
    instanceSoftware.get(`drilling/cement/depth`).then(callback).catch(catchError),
  getWellDepth: (callback, catchError) => instanceSoftware.get(`drilling/well/depth`).then(callback).catch(catchError),
  setWellDepth: (callback, catchError, depth) =>
    instanceSoftware.post(`drilling/well/depth`, depth).then(callback).catch(catchError),
  updateCementDepth: (callback, catchError, amount) =>
    instanceSoftware.post(`drilling/cement/depth`, amount).then(callback).catch(catchError),
  setForceCalculationsROP: (callback, catchError, flag) =>
    instanceSoftware.post(`drilling/set/rop/force/calc`, flag).then(callback).catch(catchError),
  getForceCalculationsROP: (callback, catchError) =>
    instanceSoftware.get(`drilling/get/rop/force/status`).then(callback).catch(catchError),
  resetParams: (callback, catchError, parameter) =>
    instanceSoftware.post(`constants/reset/parameter`, parameter).then(callback).catch(catchError),
  resetInternalParams: (callback, catchError, address) =>
    instanceSoftware.post(`constants/reset/${address}`).then(callback).catch(catchError),

  // Tools
  getAllCasing: (callback, catchError) => instanceSoftware.get('drilling/casing').then(callback).catch(catchError),
  getTools: (callback, catchError) => instanceSoftware.get('drilling/tools').then(callback).catch(catchError),
  updateTools: (callback, catchError, id, data) =>
    instanceSoftware.patch(`drilling/tools/${id}`, data).then(callback).catch(catchError),
  updateToolsAsBulk: (callback, catchError, data) =>
    instanceSoftware.patch('drilling/tools/group/update/by/click', data).then(callback).catch(catchError),
  deleteTools: (callback, catchError, id) =>
    instanceSoftware.delete(`drilling/tools/${id}`).then(callback).catch(catchError),
  updateSortsTools: (callback, catchError, data) =>
    instanceSoftware.patch(`drilling/tools/sort/update`, data).then(callback).catch(catchError),
  getToolsInserted: (callback, catchError, query) =>
    instanceSoftware
      .get(`drilling/tools/inserted?${query || ''}`)
      .then(callback)
      .catch(catchError),
  toggleTools: (callback, catchError, id) =>
    instanceSoftware.patch(`drilling/tools/${id}/toggle/usable`).then(callback).catch(catchError),
  toggleToolsBulk: (callback, catchError, ids) =>
    instanceSoftware.patch(`drilling/tools/toggle/usable/bulk`, ids).then(callback).catch(catchError),
  addCasing: (callback, catchError, data) =>
    instanceSoftware.post('drilling/casing', data).then(callback).catch(catchError),
  editCase: (callback, catchError, id, data) =>
    instanceSoftware.patch(`drilling/casing/${id}`, data).then(callback).catch(catchError),
  deleteCase: (callback, catchError, id) =>
    instanceSoftware.delete(`drilling/casing/${id}`).then(callback).catch(catchError),
  getAlarmMeter: (callback, catchError, amount) =>
    instanceSoftware.get(`/drilling/meter/alarm/depth`, amount).then(callback).catch(catchError),
  updateAlarmMeter: (callback, catchError, amount) =>
    instanceSoftware.post(`/drilling/meter/alarm/depth`, amount).then(callback).catch(catchError),
  toggleAlarmMeter: (callback, catchError, toggle) =>
    instanceSoftware.post(`/drilling/switch/alarm/depth`, toggle).then(callback).catch(catchError),
  getAlarmMeterStatus: (callback, catchError) =>
    instanceSoftware.get(`/drilling/switch/alarm/depth`).then(callback).catch(catchError),

  // Casing tools
  getAllCasingTools: (callback, catchError) => instanceSoftware.get('drilling/case').then(callback).catch(catchError),
  getCasingToolsInserted: (callback, catchError) =>
    instanceSoftware.get('drilling/case/inserted').then(callback).catch(catchError),
  addCasingTool: (callback, catchError, data) =>
    instanceSoftware.post('drilling/case', data).then(callback).catch(catchError),
  updateCasingTool: (callback, catchError, id, data) =>
    instanceSoftware.patch(`drilling/case/${id}`, data).then(callback).catch(catchError),
  deleteCasingTool: (callback, catchError, id) =>
    instanceSoftware.delete(`drilling/case/${id}`).then(callback).catch(catchError),
  toggleCasingTools: (callback, catchError, id) =>
    instanceSoftware.patch(`drilling/case/${id}/toggle/usable`).then(callback).catch(catchError),
  updateSortsCasingTools: (callback, catchError, data) =>
    instanceSoftware.patch(`drilling/case/sort/update`, data).then(callback).catch(catchError),
  toggleCasingToolsBulk: (callback, catchError, ids) =>
    instanceSoftware.patch(`drilling/case/toggle/usable/bulk`, ids).then(callback).catch(catchError),
  // Constants
  getMetaKey: (callback, catchError, key) =>
    instanceSoftware.get(`constants/meta/${key}`).then(callback).catch(catchError),
  setMetaKey: (callback, catchError, key, data) =>
    instanceSoftware.post(`constants/meta/${key}`, data).then(callback).catch(catchError),
  getSignals: (callback, catchError) => instanceSoftware.get(`constants/signals`).then(callback).catch(catchError),
  updateStandPipe: (callback, catchError, data) =>
    instanceSoftware.post(`drilling/tools/stand`, data).then(callback).catch(catchError),
  restoreStandToInsertedPipes: (callback, catchError, data) =>
    instanceSoftware.post(`/drilling/tools/restore`, data).then(callback).catch(catchError),
  renameStandToInsertedPipes: (callback, catchError, data) =>
    instanceSoftware.post(`/drilling/tools/rename/stand`, data).then(callback).catch(catchError),
  toggleStatusStandToInsertedPipes: (callback, catchError, data) =>
    instanceSoftware.post(`/drilling/tools/toggle/stand/status`, data).then(callback).catch(catchError),
  counterPipeOne: (callback, catchError, counterNum) =>
    instanceSoftware.post(`/constants/ref/active/standpipe/${counterNum}`).then(callback).catch(catchError),
  getAllUnitsCategory: (callback, catchError) =>
    instanceSoftware.get(`constants/category/units`).then(callback).catch(catchError),
  addCategory: (callback, catchError, data) =>
    instanceSoftware.post(`constants/category/units`, data).then(callback).catch(catchError),
  updateCategory: (callback, catchError, id, data) =>
    instanceSoftware.patch(`constants/category/units/${id}`, data).then(callback).catch(catchError),
  deleteCategory: (callback, catchError, id) =>
    instanceSoftware.delete(`constants/category/units/${id}`).then(callback).catch(catchError),
  // System settings
  getChartLimitNumber: (callback, catchError) =>
    instanceSoftware.get(`constants/setting/chart-number`).then(callback).catch(catchError),
  updateChartLimitNumber: (callback, catchError, data) =>
    instanceSoftware.patch(`constants/setting/chart-number/update`, data).then(callback).catch(catchError),
  getPdfResolution: (callback, catchError) =>
    instanceSoftware.get(`constants/read/frequency/time`).then(callback).catch(catchError),
  updatePdfResolution: (callback, catchError, data) =>
    instanceSoftware.post(`constants/update/frequency/time`, data).then(callback).catch(catchError),
  // System settings
  getAllThreads: (callback, catchError) => instanceSoftware.get(`constants/threads`).then(callback).catch(catchError),
  addToolByForm: (callback, catchError, data) =>
    instanceSoftware.post(`drilling/tools`, data).then(callback).catch(catchError),
  getRedisKeys: (callback, catchError, data) => instanceSoftware.get(`redis`, data).then(callback).catch(catchError),
  getRedisKey: (callback, catchError, key) => instanceSoftware.get(`plc/redis/${key}`).then(callback).catch(catchError),
  getRedisKeyFromContext: (callback, catchError, key) =>
    instanceSoftware.get(`contexts/redis/read/${key}`).then(callback).catch(catchError),
  setRedisKeyToContext: (callback, catchError, key, data) =>
    instanceSoftware.post(`contexts/redis/read/${key}`, data).then(callback).catch(catchError),
  // color
  addColor: (callback, catchError, data) =>
    instanceSoftware.post(`constants/colors`, data).then(callback).catch(catchError),
  updateColor: (callback, catchError, id, data) =>
    instanceSoftware.patch(`constants/colors/${id}`, data).then(callback).catch(catchError),
  deleteColor: (callback, catchError, id) =>
    instanceSoftware.delete(`constants/colors/${id}`).then(callback).catch(catchError),
  trippingHoursUpdate: (callback, catchError, data) =>
    instanceSoftware.patch(`constants/tripping/hours`, data).then(callback).catch(catchError),
  trippingHours: (callback, catchError) =>
    instanceSoftware.get(`constants/get/tripping/hours`).then(callback).catch(catchError),
  updateDifferentialPressure: (callback, catchError, data) =>
    instanceSoftware.post(`constants/differential/pressure `, data).then(callback).catch(catchError),
  differentialPressure: (callback, catchError) =>
    instanceSoftware.get(`constants/get/differential/pressure`).then(callback).catch(catchError),
  toggleGainLossActivation: (callback, catchError, flag) =>
    instanceSoftware.post(`constants/active/tanks/${flag}/running`).then(callback).catch(catchError),
  isActiveGainLoss: (callback, catchError) =>
    instanceSoftware.get(`constants/active/tanks/running`).then(callback).catch(catchError),

  // WITS
  getWits: (callback, catchError) => instanceSoftware.get(`/constants/wits`).then(callback).catch(catchError),
  updateWits: (callback, catchError, data, id) =>
    instanceSoftware.patch(`/constants/wits/${id}`, data).then(callback).catch(catchError),
  addWits: (callback, catchError, data) =>
    instanceSoftware.post(`/constants/wits`, data).then(callback).catch(catchError),
  deleteWits: (callback, catchError, id) =>
    instanceSoftware.delete(`/constants/wits/${id}`).then(callback).catch(catchError),
  // CONNECTORS KAFKA
  getRemoteConnector: (callback, catchError, headers = {}) =>
    instanceSoftware.get('/remote/connectors', { headers }).then(callback).catch(catchError),
  readRemoteConnector: (callback, catchError, connectors, headers = {}) =>
    instanceSoftware.get(`/remote/status/${connectors}`, { headers }).then(callback).catch(catchError),
  syncRemoteConnector: (callback, catchError, headers = {}) =>
    instanceSoftware.post('/remote/connectors', null, { headers }).then(callback).catch(catchError),
  createRemoteConnector: (callback, catchError, payload, headers = {}) =>
    remoteSoftware.post('/remote/connectors', payload, { headers }).then(callback).catch(catchError),
  deleteRemoteConnector: (callback, catchError, name, headers = {}) =>
    remoteSoftware.delete(`/remote/connectors?name=${name}`, { headers }).then(callback).catch(catchError),
  // OPERATIONAL CODE
  getOperationalCode: (callback, catchError) =>
    instanceSoftware.get(`contexts/operational/code`).then(callback).catch(catchError),
  addOperationalCode: (callback, catchError, data) =>
    instanceSoftware.post(`contexts/operational/code`, data).then(callback).catch(catchError),
  editOperationalCode: (callback, catchError, data) =>
    instanceSoftware.patch(`contexts/operational/code/${data.id}`, data).then(callback).catch(catchError),
  deleteOperationalCode: (callback, catchError, id) =>
    instanceSoftware.delete(`contexts/operational/code/${id}`).then(callback).catch(catchError),
  operatedLogs: (callback, catchError) =>
    instanceSoftware.get(`/contexts/operation/action`).then(callback).catch(catchError),
  getOperatedLog: (callback, catchError, id) =>
    instanceSoftware.get(`/contexts/operation/action/${id}`).then(callback).catch(catchError),
  deleteOperatedLogs: (callback, catchError, id) =>
    instanceSoftware.delete(`/contexts/operation/action/${id}`).then(callback).catch(catchError),
  addOperatedLogs: (callback, catchError, data) =>
    instanceSoftware.post(`/contexts/operation/action`, data).then(callback).catch(catchError),
  editOperatedLogs: (callback, catchError, data, id) =>
    instanceSoftware.patch(`/contexts/operation/action/${id}`, data).then(callback).catch(catchError),
};
