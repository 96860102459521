export  const listOfTimeRes = [
  { id: 1, value: 1000, name: '1 sec' },
  { id: 2, value: 2000, name: '2 sec' },
  { id: 3, value: 3000, name: '3 sec' },
  { id: 4, value: 4000, name: '4 sec' },
  { id: 5, value: 5000, name: '5 sec' },
  { id: 6, value: 6000, name: '6 sec' },
  { id: 7, value: 7000, name: '7 sec' },
  { id: 8, value: 8000, name: '8 sec' },
  { id: 9, value: 9000, name: '9 sec' },
  { id: 10, value: 10000, name: '10 sec' },
];