export const antdComponentsTheme = {
  Checkbox: {
    colorPrimary: '#3C8CA3',
    colorPrimaryHover: '#3C8CA3',
    colorBgContainerDisabled: '#999999'
  },
  Tabs: {
    colorPrimary: '#3C8CA3',
    colorPrimaryHover: '#3C8CA3',
  },
  Radio: {
    colorPrimary: '#3C8CA3',
    colorPrimaryHover: '#3C8CA3',
  },
};
