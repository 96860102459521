import { Input, Space } from 'antd';
import { httpService } from 'api/api';
import ButtonWithIcon from 'components/shared/ButtonWithIcon';
import LableInputs from 'components/shared/LableInputs';
import ApiToastHandle from 'containers/ApiToastHandle';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiErrorHandler from 'utils/ApiErrorHandler';

const AccountSettings = () => {
  const { user } = useSelector((state) => state.general);

  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    confirm_password: '',
    loadingUpdateName: false,
    loadingChangePass: false,
  });

  useEffect(() => {
    if (user) {
      setState((prevState) => ({
        ...prevState,
        email: user?.email,
        name: user?.name,
      }));
    }
  }, [user]);

  const handleChange = (name, event) => {
    const { value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const submitResetPasword = (e) => {
    e.preventDefault();
    const { password, confirm_password } = state;

    if (password !== confirm_password) {
      ApiToastHandle(200, 'Passwords do not match', 'warning');
      return;
    }

    setState((prev) => ({
      ...prev,
      loadingChangePass: true,
    }));

    const data = {
      password,
      confirm_password,
    };

    const response = (res) => {
      if (res.status === 200) {
        ApiToastHandle(200, 'password updated successfully', 'success');
        ApiToastHandle(200, 'logging out...', 'success');
        setState((prev) => ({
          ...prev,
          password: '',
          confirm_password: '',
          loadingChangePass: false,
        }));
        Cookies.remove('userToken');
        // Cookies.remove('refresh');
        Cookies.remove('is_active');
        Cookies.remove('role');
        Cookies.remove('username');
        Cookies.remove('activeTab');
        Cookies.remove('currentSetup');
        Cookies.remove('dark_number');
        setTimeout(() => {
          window.location.replace('/login');
        }, 3000);
      }
    };

    const error = (err) => {
      setState((prev) => ({
        ...prev,
        loadingChangePass: false,
      }));
      ApiErrorHandler(err);
    };

    httpService.resetPassword(response, error, data);
  };

  const updateUsername = () => {
    //  this is user's id that had been set in the coockies
    const userId = Cookies.get('dark_number');
    const { name } = state;

    if (!name) {
      ApiToastHandle(200, 'fill the name input', 'warning');
      return;
    }

    if (!userId) {
      ApiToastHandle(200, `can't update user information, please login again and try`, 'warning');
      return;
    }

    setState((prev) => ({
      ...prev,
      loadingUpdateName: true,
    }));

    const response = (res) => {
      if (res.status === 200) {
        ApiToastHandle(200, 'information updated successfully', 'success');
        setState((prev) => ({
          ...prev,
          name: res?.data?.name,
          loadingUpdateName: false,
        }));
      }
    };

    const error = (err) => {
      setState((prev) => ({
        ...prev,
        loadingUpdateName: false,
      }));
      ApiErrorHandler(err);
    };

    httpService.updateUserInfo(response, error, userId, { name });
  };

  return (
    <Space direction="vertical" className="w-full">
      <h2 className="text-base font-semibold">Account Info</h2>
      <div className="rounded p-4 border border-gray-200 mb-10 dark:border-lightDark bg-white dark:bg-dark w-full">
        <Space.Compact className="flex justify-between items-start w-full p-3" block>
          <Space direction="vertical" className="w-full gap-y-5">
            <div className="space-y-1 relative max-w-md">
              <LableInputs text="Full Name" />
              <Input
                name="name"
                value={state.name}
                required
                className="rounded-sm my-2"
                onChange={(e) => handleChange('name', e)}
              />
            </div>
            <div className="space-y-1 relative max-w-md">
              <LableInputs text="Email" />
              <Input
                name="email"
                type="email"
                value={state.email}
                required
                className="rounded-sm my-2"
                onChange={(e) => handleChange('email', e)}
                autoComplete="off"
                disabled
              />
            </div>
            <div className="flex justify-end items-center w-full">
              <ButtonWithIcon
                title="Update"
                onClick={updateUsername}
                loading={state.loadingUpdateName}
                className="bg-primary text-white font-semibold border-primary hover:text-white hover:bg-primary/90 hover:border-primary/90 disabled:bg-gray-300 disabled:border-gray-300 rounded-sm min-w-[100px]"
              />
            </div>
          </Space>
        </Space.Compact>
      </div>
      <h2 className="text-base font-semibold">Change Password</h2>
      <div className="rounded p-4 border border-gray-200 mb-10 dark:border-lightDark bg-white dark:bg-dark">
        <Space.Compact className="flex justify-between items-start w-full p-3" block>
          <form action="" onSubmit={submitResetPasword} className=" w-full">
            <Space direction="vertical" className="w-full gap-y-5">
              <div className="space-y-1 relative max-w-md">
                <LableInputs text="New password" />
                <Input.Password
                  name="password"
                  required
                  className="rounded-sm my-2"
                  placeholder="password"
                  onChange={(e) => handleChange('password', e)}
                  autoComplete="off"
                />
              </div>
              <div className="space-y-1 relative max-w-md">
                <LableInputs text="Confirm Password" />
                <Input.Password
                  name="confirm_password"
                  required
                  className="rounded-sm my-2"
                  placeholder="confirm password"
                  onChange={(e) => handleChange('confirm_password', e)}
                  autoComplete="off"
                />
              </div>
              <div className="flex justify-end items-center w-full">
                <ButtonWithIcon
                  title="Confirm"
                  type="submit"
                  loading={state.loadingChangePass}
                  className="bg-primary text-white font-semibold border-primary hover:text-white hover:bg-primary/90 hover:border-primary/90 disabled:bg-gray-300 disabled:border-gray-300 rounded-sm min-w-[100px]"
                />
              </div>
            </Space>
          </form>
        </Space.Compact>
      </div>
    </Space>
  );
};

export default AccountSettings;
