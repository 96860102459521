import React from 'react';

const Icon = ({ size, color }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3335 2.5H3.3335V13.3333" stroke={color || "black"} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.66699 5.83398H16.667V15.834C16.667 16.276 16.4914 16.6999 16.1788 17.0125C15.8663 17.3251 15.4424 17.5007 15.0003 17.5007H8.33366C7.89163 17.5007 7.46771 17.3251 7.15515 17.0125C6.84259 16.6999 6.66699 16.276 6.66699 15.834V5.83398Z"
      stroke={color || "black"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
