import React from 'react';

const Icon = ({color, size}) => (
  <svg width={size ||"24"} height={size ||"24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="9" stroke={color || "#1C1C1C"} />
    <path d="M8 12L11 15L16 9" stroke={color || "#1C1C1C"} />
  </svg>
);

export default Icon;
