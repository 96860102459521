import { Button } from 'antd';
import React from 'react';

const ButtonWithIcon = ({ onClick, icon, title, className, type, disabled, width, loading }) => (
  <Button
    loading={loading}
    htmlType={type}
    disabled={disabled}
    onClick={onClick}
    style={{ boxShadow: 'none' }}
    className={`
      ${width || 'w-fit'}
      ${
        disabled
          ? 'border border-gray-300 bg-gray-300 text-white hover:bg-gray-300 hover:border-gray-300 hover:text-white'
          : className ||
            'border-primary focus:border-primary hover:border-primary active:border-primary text-primary hover:text-primary'
      }
      rounded
      hover:scale-105
      active:scale-95
      active::text-primary
      hover:opacity-100
      ${className}
      px-4
      text-sm

       `}
  >
    {icon ? (
      <div
        className={`
          flex
          items-center
          ${title && 'space-x-1'}
          w-fit
          mx-auto
        `}
      >
        {title && <span>{title}</span>} <span>{icon}</span>
      </div>
    ) : (
      <span>{title}</span>
    )}
  </Button>
);

export default ButtonWithIcon;
