import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleAudibleAlarm } from 'store/actions/audibleAlarm';
import Cookies from 'js-cookie';
import AlarmOn from 'components/icons/AlarmOn';
import AlarmOff from 'components/icons/AlarmOff';
import { httpService } from 'api/api';
import { currentUser } from 'store/actions/general';
import ApiErrorHandler from 'utils/ApiErrorHandler';

const ToggleAudibleAlarm = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.general);
  const [alertIsOn, setAlertIsOn] = useState(user?.audible_alert);

  useEffect(() => {
    setAlertIsOn(user?.audible_alert);
  }, [user]);

  const toggleAudibleAlarmUser = () => {
    const response = (res) => {
      const audibleStatus = res.data?.audible_alert;
      Cookies.set('alertStatus', audibleStatus);
      setAlertIsOn(audibleStatus);
      dispatch(toggleAudibleAlarm(audibleStatus));
      dispatch(currentUser());
    };

    const error = (err) => {
      ApiErrorHandler(err);
    };

    httpService.toggleAudibleAlarmUser(response, error);
  };

  return (
    <div className={`h-6 pl-[2px] ${alertIsOn ? 'text-success' : 'text-error'}`}>
      <button
        onClick={toggleAudibleAlarmUser}
        className=" p-0 m-0 flex justify-start items-center text-sm w-full gap-x-2 capitalize focus-visible:outline-none"
        type="button"
      >
        <span className="pl-[2px]">
          {!alertIsOn ? (
            <AlarmOff color={alertIsOn ? '#6ABE39' : '#E84749'} size={17} />
          ) : (
            <AlarmOn color={alertIsOn ? '#6ABE39' : '#E84749'} size={17} />
          )}
        </span>
        <span>Audible Alarm is {!alertIsOn ? 'inactive' : 'active'}</span>
      </button>
    </div>
  );
};

export default ToggleAudibleAlarm;
