import React from 'react';

const Icon = ({ color }) => (
  <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.223633 2.33105V0H10.8906V2.33105H0.223633ZM0.223633 6.50977V4.15723H10.8906V6.50977H0.223633Z"
      fill={color || "#1C1C1C"}
    />
  </svg>
);

export default Icon;
