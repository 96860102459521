import React from 'react';

const Icon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="white" />
    <path
      d="M6 13.0172L9.64897 16.6662C10.0856 17.1028 10.8097 17.0433 11.1692 16.5412L18 7"
      stroke="#6ABE39"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Icon;
