import { combineReducers } from 'redux';
import wellProfileReducer from './wellProfile';
import liveData from './liveData';
import settings from './settings';
import general from './general';
import memos from './memos';
import plc from './plc';
import syncCharts from './syncCharts';
import addTools from './addTools';
import report from './report';
import audibleAlarm from './audibleAlarm';
import drillerMenu from './drillerMenu';

export default combineReducers({
  wellProfile: wellProfileReducer,
  liveData,
  settings,
  general,
  memos,
  plc,
  syncCharts,
  addTools,
  report,
  audibleAlarm,
  drillerMenu,
});
