import { httpService } from 'api/api';
import Cookies from 'js-cookie';
import ApiErrorHandler from 'utils/ApiErrorHandler';
import * as types from '../types';

// getUser permission and role
export const currentUser = () => (dispatch) => {
  const response = (res) => {
    const { id } = res.data;
    Cookies.set('dark_number', id);
    dispatch({
      type: types.GET_CURRENT_USER,
      payload: res.data,
    });
  };

  const error = (err) => {
    if (err.response?.status === 401) {
      Cookies.remove('userToken');
      Cookies.remove('refresh');
      Cookies.remove('is_active');
      Cookies.remove('username');
      Cookies.remove('role');
      Cookies.remove('dark_number');
      if (window.location.pathname !== '/login') {
        // ApiToastHandle(400, 'your session has been expired', 'error');
        window.location.replace('/login');
        // setTimeout(() => {
        //   window.location.replace('/login');
        // }, 1000);
      }
    }
  };

  httpService.currentUser(response, error);
};

export const loadingLayer = (status) => (dispatch) => {
  dispatch({
    type: types.SET_OVERLAY_LOADING,
    payload: status,
  });
};

export const updateUserInfo = (data) => (dispatch) => {
  const { userId, theme } = data;

  const response = (res) => {
    dispatch({
      type: types.UPDATE_USER_INFO,
      payload: res.data,
    });
    if (data.theme || !data.theme) {
      window.location.reload();
    }
  };

  const error = (err) => {
    ApiErrorHandler(err);
    setTimeout(() => {
      if (data.theme || !data.theme) {
        localStorage.setItem('isDarkMode', !data.theme);
        window.location.reload();
      }
    }, 2000);
  };

  httpService.updateUserInfo(response, error, userId, { theme });
};

export const getAllTraces = () => async (dispatch) => {
  let traces = [];
  const response = async (res) => {
    traces = await res.data;
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getAllTraces(response, error);

  dispatch({
    type: types.GET_ALL_TRACES,
    payload: traces,
  });
};

export const isAddSensor = (data) => async (dispatch) => {
  dispatch({
    type: types.IS_ADD_UNIT,
    payload: data,
  });
};

export const getProjects = () => async (dispatch) => {
  let projects = [];
  const response = (res) => {
    if (res.status === 200) {
      projects = res.data;
      Cookies.set('projectsLength', JSON.stringify(projects?.length));
    }
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getProjects(response, error);

  dispatch({
    type: types.GET_PROJECTS,
    payload: projects,
  });
};

export const getUserExportHistories = () => async (dispatch) => {
  let histories = [];
  const response = (res) => {
    if (res.status === 200) {
      histories = res.data;
    }
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getUserExportHistories(response, error);

  dispatch({
    type: types.GET_USER_EXPORT_HISTORIES,
    payload: histories,
  });
};

export const getInstalledTraces = (id) => async (dispatch) => {
  // let traces = [];
  const response = (res) => {
    // traces = res.data;
    dispatch({
      type: types.GET_INSTALLED_TRACES,
      payload: res.data,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getInstalledTraces(response, error, id);

  // dispatch({
  //   type: types.GET_INSTALLED_TRACES,
  //   payload: traces,
  // });
};

export const getAllUnits = () => async (dispatch) => {
  let units = [];
  const response = (res) => {
    units = res.data;
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getAllUnits(response, error);

  dispatch({
    type: types.GET_ALL_UNITS,
    payload: units,
  });
};

export const getAllUnitsCategory = () => async (dispatch) => {
  let categories = [];

  const response = (res) => {
    categories = res.data;
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getAllUnitsCategory(response, error);

  dispatch({
    type: types.GET_ALL_UNITS_CATEGORY,
    payload: categories,
  });
};

export const setSelectedProject = (data) => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_PROJECT,
    payload: data,
  });
};

export const canFinishProject = (data) => (dispatch) => {
  dispatch({
    type: types.SET_FINISH_PROJECT,
    payload: data,
  });
};

export const toggleFocusMode = () => (dispatch) => {
  dispatch({
    type: types.TOGGLE_FOCUS_MODE,
  });
};

export const getAllColors = () => async (dispatch) => {
  let colors = [];
  const response = (res) => {
    if (res.status === 200) {
      colors = res.data;
    }
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getAllColors(response, error);

  dispatch({
    type: types.GET_ALL_COLORS,
    payload: colors,
  });
};

export const getSensors = () => async (dispatch) => {
  let sensors = [];
  const response = (res) => {
    if (res.status === 200) {
      sensors = res.data;
    }
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getSensors(response, error);

  dispatch({
    type: types.GET_SENSORS,
    payload: sensors,
  });
};

export const getAutoDepth = (key) => async (dispatch) => {
  let autoDepthstatus = false;
  const response = (res) => {
    if (res.status === 200) {
      autoDepthstatus = res?.data;
    }
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getAutoDepth(response, error, key);

  dispatch({
    type: types.GET_REDIS_AUTO_DEPTH,
    payload: autoDepthstatus,
  });
};

export const getUserStorage = (userName) => (dispatch) => {
  if (!userName) return;

  const response = (res) => {
    if (res.status === 200 && res?.data?.value) {
      dispatch({
        type: types.GET_USER_STORAGE,
        payload: res?.data?.value,
      });
    }
  };

  const error = async (err) => {
    if (err?.response?.status === 404) return '';
    // ApiErrorHandler(err);
  };

  httpService.getMetaKey(response, error, `${userName}_storage`);
};

export const updateUserStorage = (userName, data) => (dispatch) => {
  const response = (res) => {
    dispatch(getUserStorage(userName));
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  httpService.setMetaKey(response, error, `${userName}_storage`, data);
};

export const getSafetyToolsStatus = () => (dispatch) => {
  const response = (res) => {
    if (res.status === 200) {
      dispatch({
        type: types.SET_SAFETY_TOOLS_STATUS,
        payload: res?.data?.value,
      });
    }
  };

  const error = async (err) => {
    if (err?.response?.status === 404) return '';
    // ApiErrorHandler(err);
  };

  httpService.getMetaKey(response, error, 'safetyToolsStatus');
};

export const setSafetyToolsStatus = (data) => (dispatch) => {
  const response = (res) => {
    dispatch(getSafetyToolsStatus());
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  httpService.setMetaKey(response, error, 'safetyToolsStatus', data);
};

export const getOperationalCode = () => (dispatch) => {
  const response = (res) => {
    dispatch({
      type: types.GET_OPERATIONAL_CODE,
      payload: res?.data,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  httpService.getOperationalCode(response, error);
};

export const addOperatedLogs = () => (dispatch) => {
  const response = (res) => {
    dispatch({
      type: types.GET_OPERATIONAL_CODE,
      payload: res?.data?.value,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  httpService.addOperatedLogs(response, error);
};
